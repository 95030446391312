export enum SIZE_FONT_ENUM {
  HERO_TITLE = 'heroTitle',
  HERO_SUBTITLE = 'heroSubtitle',
  HERO_MIDDLE = 'heroMiddle',
  EXTRA_LARGE = 'extraLarge',
  SIGNATURE = 'signature',
  BUTTON = 'button',
  PRIZE = 'prize',

  // Старые:
  DEFAULT = 'default',
  SMALL = 'small',
  INPUT = 'input',
  HEADING = 'heading',
  LABEL = 'label',
  ALERT = 'alert',
  MAIN = 'main',
  TITLE = 'title',
  CHIP = 'chip',
  MEDIUM = 'medium',
  AMOUNT = 'amount',
  VERY_SMALL = 'verySmall',
  DATE_COUNT = 'date_count',
  LARGE = 'large',
}

export type SIZE_FONT_TYPE = `${SIZE_FONT_ENUM}`;

export const SIZE_FONT_DATA = {
  [SIZE_FONT_ENUM.DEFAULT]: 14,
  [SIZE_FONT_ENUM.CHIP]: 14,
  [SIZE_FONT_ENUM.HEADING]: 24,
  [SIZE_FONT_ENUM.LABEL]: 16,
  [SIZE_FONT_ENUM.INPUT]: 16,
  [SIZE_FONT_ENUM.ALERT]: 12,
  [SIZE_FONT_ENUM.MAIN]: 18,
  [SIZE_FONT_ENUM.AMOUNT]: 26,
  [SIZE_FONT_ENUM.VERY_SMALL]: 10,
  [SIZE_FONT_ENUM.DATE_COUNT]: 36,

  //Новые:
  [SIZE_FONT_ENUM.SMALL]: 12,
  [SIZE_FONT_ENUM.TITLE]: 18,
  [SIZE_FONT_ENUM.MEDIUM]: 20,
  [SIZE_FONT_ENUM.LARGE]: 97,
  [SIZE_FONT_ENUM.EXTRA_LARGE]: 165,
  [SIZE_FONT_ENUM.HERO_TITLE]: 90,
  [SIZE_FONT_ENUM.PRIZE]: 80,
  [SIZE_FONT_ENUM.HERO_MIDDLE]: 64,
  [SIZE_FONT_ENUM.HERO_SUBTITLE]: 55,
  [SIZE_FONT_ENUM.BUTTON]: 24,
  [SIZE_FONT_ENUM.SIGNATURE]: 25,
};

export enum SIZE_LAYOUT_ENUM {
  DEFAULT = 'default',
  SMALL = 'small',
}

export type SIZE_LAYOUT_TYPE = `${SIZE_LAYOUT_ENUM}`;

export const SIZE_LAYOUT_DATA = {
  [SIZE_LAYOUT_ENUM.DEFAULT]: 1200,
  [SIZE_LAYOUT_ENUM.SMALL]: 720,
};

export enum SIZE_GRID_ENUM {
  DEFAULT = 'default', // ДЕФОЛТНЫЙ
  INPUT = 'input', // Отступы между инпутами в форме
  MOD = 'mod', // Отступы между компонентами в модуле
  COM = 'com', // Отступы между компонентами в итеме сущносте
  FORM = 'form', // Отступы между компонентами в итеме сущносте
  ELEM = 'elem', // Отступы между элементами в итеме сущносте
  LIST = 'list', // Отступы между итемами в списке итемов
}

export type SIZE_GRID_TYPE = `${SIZE_GRID_ENUM}`;

export const SIZE_GRID_DATA = {
  [SIZE_GRID_ENUM.DEFAULT]: 16,
  [SIZE_GRID_ENUM.INPUT]: 12,
  [SIZE_GRID_ENUM.MOD]: 24,
  [SIZE_GRID_ENUM.FORM]: 26,
  [SIZE_GRID_ENUM.LIST]: 24,
  [SIZE_GRID_ENUM.COM]: 16,
  [SIZE_GRID_ENUM.ELEM]: 8,
};

export enum SIZE_FONT_WEIGHT_ENUM {
  LIGHT = 'light',
  REGULAR = 'regular',
  MEDIUM = 'medium',
  SEMI_BOLD = 'semi-bold',
  BOLD = 'bold',
  EXTRA_BOLD = 'extra-bold',
}

export type SIZE_FONT_WEIGHT_TYPE = `${SIZE_FONT_WEIGHT_ENUM}`;

export const SIZE_FONT_WEIGHT_DATA = {
  [SIZE_FONT_WEIGHT_ENUM.LIGHT]: 300,
  [SIZE_FONT_WEIGHT_ENUM.REGULAR]: 400,
  [SIZE_FONT_WEIGHT_ENUM.MEDIUM]: 500,
  [SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD]: 600,
  [SIZE_FONT_WEIGHT_ENUM.BOLD]: 700,
  [SIZE_FONT_WEIGHT_ENUM.EXTRA_BOLD]: 900,
};

export enum SIZE_BORDER_RADIUS_ENUM {
  DEFAULT = 'default',
  CHIP = 'chip',
  ALERT = 'alert',
}

export type SIZE_BORDER_RADIUS_TYPE = `${SIZE_BORDER_RADIUS_ENUM}`;

export const SIZE_BORDER_RADIUS_DATA = {
  [SIZE_BORDER_RADIUS_ENUM.DEFAULT]: 10,
  [SIZE_BORDER_RADIUS_ENUM.CHIP]: 5,
  [SIZE_BORDER_RADIUS_ENUM.ALERT]: 8,
};

export enum SIZE_SECTION_ENUM {
  DEFAULT = 'default',
}

export type SIZE_SECTION_TYPE = `${SIZE_SECTION_ENUM}`;

export const SIZE_SECTION_DATA = {
  [SIZE_SECTION_ENUM.DEFAULT]: 36,
  SIZE_BORDER_RADIUS_ENUM,
};

export enum SIZE_ICON_ENUM {
  DEFAULT = 'default',
  SMALL = 'small',
  LARGE = 'large',
  VERY_SMALL = 'very_small',
}

export type SIZE_ICON_TYPE = `${SIZE_ICON_ENUM}`;

export const SIZE_ICON_DATA = {
  [SIZE_ICON_ENUM.DEFAULT]: 20,
  [SIZE_ICON_ENUM.SMALL]: 16,
  [SIZE_ICON_ENUM.VERY_SMALL]: 14,
  [SIZE_ICON_ENUM.LARGE]: 24,
};
