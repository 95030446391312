import React from 'react';
import styled from 'styled-components';

import WinPrizeText from '../../../../../asset/tefal/win.png';
import WinPrize from '../../../../../asset/tefal/win-prize.png';
import WinPrizeMobile from '../../../../../asset/tefal/prize-mobile.png';
import DiamindLeft from '../../../../../asset/tefal/diamond-left.png';
import DiamindRight from '../../../../../asset/tefal/diamond-right.png';

export const Content: React.FC<{}> = ({}) => {
  return (
    <Container>
      <DiamondLeftImg src={DiamindLeft} alt="diamond" />
      <Img src={WinPrizeText} alt="win text" />
      <DiamondImg src={DiamindRight} alt="diamond" />
      <PrizeImg src={WinPrize} alt="prize" />
      <PrizeMobileImg src={WinPrizeMobile} alt="prize" />
    </Container>
  );
};

const DiamondLeftImg = styled.img`
  position: absolute;
  top: 80px;
  left: 0px;
  @media screen and (max-width: 985px) {
    display: none;
  }
`;

const DiamondImg = styled.img`
  position: absolute;
  top: 20px;
  right: 0px;
  @media screen and (max-width: 985px) {
    display: none;
  }
`;

const PrizeMobileImg = styled.img`
  width: 377px;
  height: auto;
  display: none;

  @media screen and (max-width: 720px) {
    display: block;
  }
`;

const PrizeImg = styled.img`
  width: 1200px;
  height: auto;

  @media screen and (max-width: 720px) {
    display: none;
  }
`;

const Img = styled.img`
  width: 778px;
  height: auto;
`;

const Container = styled.div`
  margin-top: 132px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
  gap: 48px;

  @media screen and (max-width: 720px) {
    margin-bottom: 20px;
    margin-top: 56px;
    gap: 30px;
  }
`;
