import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'AUTH_LOGIN_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  FIRST_NAME = 'firstName',
  PHONE = 'phone',
  CODE = 'code',
  SHOP = 'shop',
  REGISTRATION_SOURCE = 'registrationSource',
  PROMO_ID = 'promoId',
  AGE_AGREE = 'ageAgree',
  RULES_AGREE = 'rulesAgree',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.FIRST_NAME]: string;
  [FORM_VALUE_ENUM.SHOP]: SHOP_ENUM | null;
  [FORM_VALUE_ENUM.PHONE]: string;
  [FORM_VALUE_ENUM.CODE]: string;
  [FORM_VALUE_ENUM.REGISTRATION_SOURCE]: SOURCE_ENUM;
  [FORM_VALUE_ENUM.PROMO_ID]: number;
}

export const API = {
  MAIN: {
    TYPE: 'POST',
    URL: '/attempt',
  },
};

export interface ACTION_RESPONSE_INTER {
  accessToken: string;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
export enum SHOP_ENUM {
  ARSEN = 'ARSEN',
  ATB = 'ATB_MARKET',
  ASHAN = 'AUCHAN',
  BOX = 'BOX',
  BLYZENKO = 'BLYZENKO',
  VELIKA_KISHENYA = 'VELYKA_KYSHENYA',
  VOPAK = 'VOPAK',
  VARUS = 'VARUS',
  VOSTORG = 'VOSTORG',
  GROSH = 'GROSH',
  DELVI = 'DELVI',
  EKO_MARKET = 'EKO_MARKET',
  EPITSENTR = 'EPITSENTR',
  IDEAL = 'IDEAL',
  CLASS = 'KLASS',
  KOLO = 'KOLO',
  KOPIYKA = 'KOPIIKA',
  MARKETOPT = 'MARKETOPT',
  MEGA_MARKET = 'MEGA_MARKET',
  METRO_CASH_AND_CARRY = 'METRO_CASH_AND_CARRY',
  NASH_KRAY = 'NASH_KRAY',
  NOVUS = 'NOVUS',
  NOVA_LINIIA = 'NOVA_LINIIA',
  PAKKO = 'PAKKO',
  POSAD = 'POSAD',
  ROST = 'ROST',
  RUKAVYCHKA = 'RUKAVYCHKA',
  SILPO = 'SILPO',
  SPAR = 'SPAR',
  TAVRIYA = 'TAVRIYA',
  TRASH = 'TRASH!',
  TOCHKA = 'TOCHKA',
  FOZZI = 'FOZZI',
  FORA = 'FORA',
  FAINO = 'FAINO',
  CHUDO = 'CHUDO',
  JUBILEE = 'YUVILEYINII',
  SEVEN_TWO_THREE = 'SIM23',
  INSHIY = 'ІНШИЙ',
  NASHA_RIABA = 'NASHA_RIABA',
  YIZHA_SVIZHA = 'YIZHA_SVIZHA',
  MIASOMARKET = 'MIASOMARKET',
  ULTRAMARKET = 'ULTRAMARKET',
}

export const SHOP_TYPE_OPTIONS = [
  { value: SHOP_ENUM.NASHA_RIABA, label: 'Наша Ряба' },
  { value: SHOP_ENUM.YIZHA_SVIZHA, label: 'Їжа Свіжа' },
  { value: SHOP_ENUM.MIASOMARKET, label: 'Мʼясомаркет' },
  { value: SHOP_ENUM.ULTRAMARKET, label: 'Ultramarket' },
  { value: SHOP_ENUM.ARSEN, label: 'Арсен' },
  { value: SHOP_ENUM.ATB, label: 'АТБ' },
  { value: SHOP_ENUM.ASHAN, label: 'Ашан' },
  { value: SHOP_ENUM.BOX, label: 'Box' },
  { value: SHOP_ENUM.BLYZENKO, label: 'Близенько' },
  { value: SHOP_ENUM.VELIKA_KISHENYA, label: 'Велика Кишеня' },
  { value: SHOP_ENUM.VOPAK, label: 'Вопак' },
  { value: SHOP_ENUM.VARUS, label: 'Varus' },
  { value: SHOP_ENUM.VOSTORG, label: 'Восторг' },
  { value: SHOP_ENUM.GROSH, label: 'Грош' },
  { value: SHOP_ENUM.DELVI, label: 'Делві' },
  { value: SHOP_ENUM.EKO_MARKET, label: 'ЕКО маркет' },
  { value: SHOP_ENUM.EPITSENTR, label: 'Епіцентр' },
  { value: SHOP_ENUM.IDEAL, label: 'Ідеал' },
  { value: SHOP_ENUM.CLASS, label: 'Класс' },
  { value: SHOP_ENUM.KOLO, label: 'Коло' },
  { value: SHOP_ENUM.KOPIYKA, label: 'Копійка' },
  { value: SHOP_ENUM.MARKETOPT, label: 'Маркетопт' },
  { value: SHOP_ENUM.MEGA_MARKET, label: 'Мега-маркет' },
  { value: SHOP_ENUM.METRO_CASH_AND_CARRY, label: 'Метро Кеш енд Кері' },
  { value: SHOP_ENUM.NASH_KRAY, label: 'Наш край' },
  { value: SHOP_ENUM.NOVUS, label: 'Novus' },
  { value: SHOP_ENUM.NOVA_LINIIA, label: 'Нова Лiнiя' },
  { value: SHOP_ENUM.PAKKO, label: 'Пакко' },
  { value: SHOP_ENUM.POSAD, label: 'Посад' },
  { value: SHOP_ENUM.ROST, label: 'Рост' },
  { value: SHOP_ENUM.RUKAVYCHKA, label: 'Рукавичка' },
  { value: SHOP_ENUM.SILPO, label: 'Сільпо' },
  { value: SHOP_ENUM.SPAR, label: 'Spar' },
  { value: SHOP_ENUM.TAVRIYA, label: 'Таврія' },
  { value: SHOP_ENUM.TRASH, label: 'Траш!' },
  { value: SHOP_ENUM.TOCHKA, label: 'Точка' },
  { value: SHOP_ENUM.FOZZI, label: 'Фоззі' },
  { value: SHOP_ENUM.FORA, label: 'Фора' },
  { value: SHOP_ENUM.FAINO, label: 'Файно Маркет' },
  { value: SHOP_ENUM.CHUDO, label: 'Чудо Маркет' },
  { value: SHOP_ENUM.JUBILEE, label: 'Ювілейний' },
  { value: SHOP_ENUM.SEVEN_TWO_THREE, label: '723' },

  { value: SHOP_ENUM.INSHIY, label: 'Інший' },
];

export enum SOURCE_ENUM {
  TELEGRAM = 'Telegram',
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
  VIBER = 'Viber',
}
