import VALIDATION from './validation.json';

import AUTH from './auth.json';
import SANTA from './santa.json';
import CROCO from './croco.json';
import SUMMER from './summer.json';
import TEFAL from './tefal.json';

export const UA = {
  translation: {
    VALIDATION,
    AUTH,
    SANTA,
    CROCO,
    SUMMER,
    TEFAL,
  },
};
