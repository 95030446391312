import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { COLOR_ENUM, LIGHT_COLOR_DATA } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { TextElem } from '../text';
import { PROPS_TYPE } from './constant';
import { i18n } from '../../lib/lang';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import errorField from '../../asset/img/icon/errorField.svg';

import PhoneInput from 'react-phone-input-2';

export const Elem: React.FC<PROPS_TYPE> = ({
  isCroc,
  title,
  error,
  errorMessage,
  onChange,
  onBlur,
  value,
  name,
  type,
  disabled,
  icon,
  placeholder,
  className,
  maxLength,
  min,
  max,
}) => {
  const refIcon = useRef<HTMLDivElement>(null);
  const handleChange = (e: any) => {
    if (type == 'phone') {
      if (e && onChange && e.length < 14) {
        onChange({ target: { name: name, value: `+${e}` } });
      }
      // } else if (type == 'number') {
      //   if (!e.target) e.target = {};
      //   e.target.name = name;
      //   e.target.value= maxLength
      //     ? e.target.value.substr(0, maxLength)
      //     : e.target.value;
      //   console.log(e.target);
      //   if (onChange) {
      //     onChange(e);
      //   }
    } else {
      if (!e.target) e.target = {};
      e.target.name = name;
      e.target.value = maxLength
        ? e.target.value.substr(0, maxLength)
        : e.target.value;

      if (onChange) {
        onChange(e);
      }
    }
  };

  const handleBlur = (e: any) => {
    if (!e.target) e.target = {};

    e.target.name = name;
    e.target.value = e.target.value;

    if (onBlur) {
      onBlur(e);
    }
  };

  // const [inputValue, setInputValue] = useState();

  // useEffect(() => {
  //   if (value) {
  //     setInputValue(value);
  //   }
  // }, []);

  // const handleChange = (e: any) => {
  //   if (e.target.value !== inputValue) {
  //     setInputValue(e.target.value);
  //   }
  // };

  // const handleBlur = (e: any) => {
  //   if (!e.target) e.target = {};

  //   e.target.name = name;
  //   e.target.value = e.target.value;
  //   if (onChange) {
  //     onChange(e);
  //   }

  //   if (onBlur) {
  //     onBlur(e);
  //   }
  // };

  const iconSize = refIcon?.current?.clientWidth || 40;

  return (
    <Container className={className}>
      {title && (
        <Label>
          <TextElem
            color={isCroc ? 'white' : 'black'}
            size="default"
            type="medium"
            tid={title}
          />
        </Label>
      )}
      <Content>
        {type == 'phone' ? (
          <PhoneInputCustom
            isCroc={isCroc}
            disabled={disabled}
            error={error && !disabled}
            country={'ua'}
            countryCodeEditable={false}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            inputProps={{
              name: name,
            }}
          />
        ) : (
          <>
            <CustomInput
              isCroc={isCroc}
              disabled={disabled}
              name={name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={error && !disabled}
              type={type}
              value={value}
              // value={inputValue}
              iconSize={iconSize}
              placeholder={placeholder && i18n.t(placeholder)}
              className={className}
              maxLength={maxLength}
              min={min}
              max={max}
            />
            <Icon ref={refIcon}>{icon}</Icon>
          </>
        )}
      </Content>
      <ErrorContainer className="errorContainer">
        {(error || errorMessage) && (
          <>
            {!disabled && (
              <ErrorMessage>
                <TextElem size="alert" color="error">
                  {errorMessage}
                </TextElem>
                <img src={errorField} />
              </ErrorMessage>
            )}
          </>
        )}
      </ErrorContainer>
    </Container>
  );
};

const CustomInput = styled.input<{
  error?: boolean;
  disabled?: boolean;
  onIonChange?: Function;
  type?: string;
  iconSize: number;
  isCroc?: boolean;
}>`
  font-family: 'Graphik' !important;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${LIGHT_COLOR_DATA[COLOR_ENUM.BLACK]};
  padding: ${({ isCroc }) => (isCroc ? ' 15px 16px' : '10px 12px')};
  border-radius: 4px;
  border: 1px solid #d7dde6;
  background: #fdfeff;

  padding-right: ${({ iconSize }) => iconSize}px;
  text-align: start;
  width: 100%;
  outline: none;
  transition: color ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.DEFAULT]},
    border-color ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.DEFAULT]};

  &:hover {
    border: ${({ disabled }) => !disabled && '1px solid #8C73B4'};
  }

  &:focus-within {
    box-shadow: ${LIGHT_COLOR_DATA[COLOR_ENUM.INPUT_ACTIVE_SHADOW]};
    background: ${LIGHT_COLOR_DATA[COLOR_ENUM.INPUT_ACTIVE]};
    border: 1px solid #8c73b4;
  }
  ::placeholder {
    color: #8c8f94;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ disabled }) => {
    if (disabled) {
      return css`
        border: 1px solid #e3e8ef;
        background: #eff3f9;
      `;
    }
  }}

  ${({ error }) =>
    error &&
    css`
      border-color: ${LIGHT_COLOR_DATA[COLOR_ENUM.ERROR]} !important;
    `}
`;

const PhoneInputCustom = styled(PhoneInput)<{
  error?: boolean;
  isCroc?: boolean;
}>`
  && div {
    display: none;
  }
  && input {
    font-family: 'Graphik' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    color: ${LIGHT_COLOR_DATA[COLOR_ENUM.BLACK]};
    padding: ${({ isCroc }) => (isCroc ? ' 15px 16px' : '9px 16px')};
    border-radius: 4px;
    background: #ffff;

    border: ${({ error }) =>
      error ? '1px solid #E30613 !important' : '1px solid #D7DDE6'};

    text-align: start;
    width: 100%;
    outline: none;
    transition: color ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.DEFAULT]},
      border-color ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.DEFAULT]};

    &:hover {
      border: ${({ disabled }) => !disabled && '1px solid #8C73B4'};
    }

    &:focus-within {
      box-shadow: ${LIGHT_COLOR_DATA[COLOR_ENUM.INPUT_ACTIVE_SHADOW]};
      background: ${LIGHT_COLOR_DATA[COLOR_ENUM.WHITE]};
      border: 1px solid #8c73b4;
    }
    ::placeholder {
      color: #8c8f94;
    }

    ${({ disabled }) => {
      if (disabled) {
        return css`
          border: 1px solid #e3e8ef;
          background: #eff3f9;
        `;
      }
    }}
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;

const Content = styled.div`
  position: relative;
`;

const ErrorContainer = styled.div`
  height: 13.19px;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 4px;
  width: 100%;
`;

const Icon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: max-content;
  box-sizing: content-box;
  z-index: 2;
`;
const Label = styled.div``;
