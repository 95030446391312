import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import BackgroundImage from '../../../../asset/tefal/recipe-background.png';
import BackgroundMobileImage from '../../../../asset/tefal/recipe-background-mobile.png';
import BackgroundMobileImageSecond from '../../../../asset/tefal/recipe-background-mobile-second.png';
import { RecipeHeroContainer } from '../../container/recipe-hero';
import { VideoContainer } from '../../container/video';
import { RecipeContainer } from '../../container/recipe';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <Wrapper>
      <Layout>
        <RecipeHeroContainer />
      </Layout>
      <VideoContainer />
      <RecipeContainer />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #382e7e;
  height: max-content;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;

  background-image: url(${BackgroundImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 1050px) {
    background-image: url(${BackgroundMobileImage});
    background-size: cover;
    background-position: calc(50% - 180px) center;
  }

  @media screen and (max-width: 390px) {
    background-image: url(${BackgroundMobileImageSecond});
    background-size: cover;
    background-position: center;
  }
`;
