import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { TextElem } from '../text';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';

export const Elem: React.FC<{
  onClick?: () => void;
  tid: string;
  href?: string;
  isTargetBlank?: boolean;
}> = ({ tid, onClick, href, isTargetBlank }) => {
  return (
    <AStyled target={isTargetBlank ? '_blank' : ''} href={href && href}>
      <ButtonContainer onClick={onClick}>
        <TitleStyled size="button" type="medium" tid={tid} />
      </ButtonContainer>
    </AStyled>
  );
};

const AStyled = styled.a`
  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.button`
  transition: 0.3s opacity ease;
  max-width: 432px;
  border-radius: 20px;
  position: relative;
  z-index: 2;
  padding: 12px 20px;
  border: 1px solid #8c73b4;

  @media screen and (max-width: 720px) {
    border-radius: 12px;
    max-width: unset;
    width: 100%;
  }

  :hover {
    border: 1px solid #a389cd;

    span {
      color: #a389cd;
    }
  }
`;

const Text = styled(TextElem)`
  font-family: 'Graphik' !important;
`;

const TitleStyled = styled(Text)`
  line-height: 1.2em;
  z-index: 100;
  position: relative;
  color: #8c73b4;
  text-transform: uppercase;

  @media screen and (max-width: 720px) {
    font-size: 16px;
  }
`;
