import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';
import ReactMarkdown from 'react-markdown';

import { FORM_VALUE_ENUM, SHOP_TYPE_OPTIONS } from './constant';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { LoaderElem } from '../../common/loader';
import { FormElem } from '../../common/form';
import { SelectElem } from '../../common/select';
import { TextElem } from '../../common/text';
import { CheckBoxElem } from '../../common/checkbox';
import { i18n } from '../../lib/lang';
import { RegistarionButton } from '../../common/registration-button';
import { WheelContent } from './frame/wheel-content';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  setFieldValue: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isDisabled: Function;
  setValue: Function;
  isSuccess?: boolean;
  successMessage?: string;
  resetForm: Function;
  data: any;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  setFieldValue,
  isLoading,
  isError,
  errorMessage,
  isDisabled,
  setValue,
  isSuccess,
  successMessage,
  resetForm,
  data,
}) => {
  const rulesHrefTitle = i18n.t('AUTH.SIGNUP.RULES_HREF_TITLE');
  const rulesLink = i18n.t('AUTH.SIGNUP.RULES_LINK');
  const [isWheelOpen, setIsWheelOpen] = useState(false);

  const isCircle = data?.isCircle;
  const prize = Array.isArray(data?.prize) ? data.prize[0] : {};

  useEffect(() => {
    if (isCircle) {
      setIsWheelOpen(true);
      const timer = setTimeout(() => {
        setIsWheelOpen(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [isCircle]);

  const handleButtonClick = () => {};

  const onChangeName = (e: any) => {
    formik.touched[e.target.name] = true;
    formik.handleChange(e);
  };

  const onChangeCode = (e: any) => {
    formik.touched[e.target.name] = true;
    // e.target.value = e.target.value.toUpperCase();
    // e.target.value = replaceCyrillicWithLatin(e.target.value);
    formik.handleChange(e);
  };

  const replaceCyrillicWithLatin = (inputString: string) => {
    const replacements: { [key: string]: string } = {
      А: 'A',
      В: 'B',
      Е: 'E',
      К: 'K',
      М: 'M',
      Н: 'H',
      О: 'O',
      Р: 'P',
      С: 'C',
      Т: 'T',
      У: 'Y',
      Х: 'X',
    };
    return inputString.replace(/[А-я]/g, (char) => replacements[char] || char);
  };

  const isShopNull = getFieldValue(FORM_VALUE_ENUM.SHOP) === null;

  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}
      <GridElem spacing={5}>
        {!isWheelOpen && (
          <TextElem
            type="bold"
            size="heading"
            tid={!prize.name ? 'TEFAL.FORM.TITLE' : 'TEFAL.FORM.WIN'}
          />
        )}

        {isWheelOpen && (
          <TextElem type="bold" size="heading" tid="TEFAL.FORM.CIRCLE" />
        )}

        <Container>
          <GridElemStyled spacing={0}>
            {isWheelOpen && <WheelContent prizeId={prize?.imgId} />}

            {!isSuccess && !isWheelOpen && (
              <GridElem spacing={0}>
                <DoubleContainer>
                  <FieldTextElem
                    name={FORM_VALUE_ENUM.FIRST_NAME}
                    onChange={onChangeName}
                    onBlur={formik.handleBlur}
                    title="AUTH.SIGNUP.FIRST_NAME.TITLE"
                    value={getFieldValue(FORM_VALUE_ENUM.FIRST_NAME)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.FIRST_NAME)}
                    error={isFieldError(FORM_VALUE_ENUM.FIRST_NAME)}
                    placeholder="AUTH.SIGNUP.FIRST_NAME.PLACEHOLDER"
                  />
                  <FieldTextElem
                    name={FORM_VALUE_ENUM.PHONE}
                    onChange={onChangeName}
                    onBlur={formik.handleBlur}
                    title="AUTH.SIGNUP.PHONE.TITLE"
                    value={getFieldValue(FORM_VALUE_ENUM.PHONE)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.PHONE)}
                    error={isFieldError(FORM_VALUE_ENUM.PHONE)}
                    placeholder="AUTH.SIGNUP.PHONE.PLACEHOLDER"
                    type="phone"
                  />
                </DoubleContainer>
                <FieldTextElem
                  name={FORM_VALUE_ENUM.CODE}
                  onChange={onChangeCode}
                  onBlur={formik.handleBlur}
                  title="AUTH.SIGNUP.CODE.TITLE"
                  value={getFieldValue(FORM_VALUE_ENUM.CODE)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.CODE)}
                  error={isFieldError(FORM_VALUE_ENUM.CODE)}
                  placeholder="AUTH.SIGNUP.CODE.PLACEHOLDER"
                />
                <SelectElem
                  name={FORM_VALUE_ENUM.SHOP}
                  title="AUTH.SIGNUP.SHOP.TITLE"
                  options={SHOP_TYPE_OPTIONS}
                  placeholder="AUTH.SIGNUP.SHOP.PLACEHOLDER"
                  onChange={setFieldValue}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.SHOP)}
                  error={isFieldError(FORM_VALUE_ENUM.SHOP)}
                  value={setValue(SHOP_TYPE_OPTIONS, FORM_VALUE_ENUM.SHOP)}
                />
                <CheckboxContainer>
                  <CheckBoxElem
                    tid="AUTH.SIGNUP.AGE_AGREE"
                    textSize="default"
                    name={FORM_VALUE_ENUM.AGE_AGREE}
                    checked={getFieldValue(FORM_VALUE_ENUM.AGE_AGREE)}
                    onChange={formik.handleChange}
                  />
                  <CheckBoxElem
                    href={rulesLink}
                    hrefTitle={rulesHrefTitle}
                    tid="AUTH.SIGNUP.RULES_AGREE"
                    textSize="default"
                    name={FORM_VALUE_ENUM.RULES_AGREE}
                    checked={getFieldValue(FORM_VALUE_ENUM.RULES_AGREE)}
                    onChange={formik.handleChange}
                  />
                </CheckboxContainer>
                <TextElemStyled
                  size="alert"
                  color="black"
                  type="regular"
                  tid="TEFAL.HOME.FOOTNOTE_MODAL"
                />
                <RegistarionButton
                  disabled={isSubmitDisabled() || isShopNull}
                  onClick={handleButtonClick}
                  tid="TEFAL.FORM.TITLE"
                />
              </GridElem>
            )}

            {/* {isSuccess && !isWheelOpen && (
              <SuccessContainer>
                <TextElem
                  size="default"
                  color="black"
                  type="medium"
                  tid={prize?.text}
                />
              </SuccessContainer>
            )} */}

            {isSuccess && !isWheelOpen && (
              <SuccessContainer>
                <ReactMarkdownStyled
                  components={{
                    a: ({ node, ...props }) => (
                      <a {...props} target="_blank" rel="noopener noreferrer" />
                    ),
                  }}
                  children={prize?.text || ''}
                />
              </SuccessContainer>
            )}

            {isSuccess && !isWheelOpen && prize?.link && (
              <AStyled target="_blank" href={prize.link}>
                <TextElem
                  color="purple"
                  type="medium"
                  size="heading"
                  tid="TEFAL.HOME.PRIZE_LINK"
                />
              </AStyled>
            )}

            {isError && (
              <ErrorContainer>
                <TextElem size="default" color="black" tid={errorMessage} />
              </ErrorContainer>
            )}
          </GridElemStyled>
        </Container>
      </GridElem>
    </FormElem>
  );
};

const ReactMarkdownStyled = styled(ReactMarkdown)`
  padding: 0px !important;
  margin: 0px !important;

  p {
    font-family: 'Graphik' !important;
    color: ${COLOR_DATA[COLOR_ENUM.BLACK]} !important;
    overflow-wrap: break-word !important;
    word-break: break-word !important;
    word-break: break-all !important;
  }

  a {
    font-family: 'Graphik' !important;
    color: ${COLOR_DATA[COLOR_ENUM.PURPLE]} !important;
    text-decoration: none !important;
  }
`;

const AStyled = styled.a`
  text-decoration: none;
  text-align: start;
  margin-bottom: 24px;
  transition: all ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :hover {
    span {
      color: #a389cd;
    }
  }
  :active {
    span {
      color: #715e96;
    }
  }
`;

const DoubleContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: 485px) {
    flex-direction: column;
    gap: unset;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  margin: 20px 0;
  background-color: rgba(255, 0, 0, 0.27);
  border-radius: 10px;
`;

const SuccessContainer = styled.div`
  display: flex;
  padding: 10px;
  gap: 4px;
  flex-direction: column;
  margin: 20px 0;
`;

const TextElemStyled = styled(TextElem)`
  margin: 20px 0;
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const GridElemStyled = styled(GridElem)`
  z-index: 2;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;
