import React, { useState } from 'react';
import styled from 'styled-components';

import { Spacing } from '../../../../theme';
import { Hero } from './frame/hero';
import { Registration } from './frame/registration';
import { Content } from './frame/content';
import { Modal } from './frame/modal';

export const Component: React.FC<{}> = ({}) => {
  const [isOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <Container>
      <Hero />
      <Registration openModal={openModal} />
      <Content />
      <Modal isOpen={isOpen} closeModal={closeModal} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  max-width: 1200px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0 20px;

  @media screen and (max-width: 720px) {
    margin-top: 0px;
  }
`;
