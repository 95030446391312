import React, { useEffect } from 'react';
import { isBrowser } from 'react-device-detect';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  SHOP_ENUM,
  SOURCE_ENUM,
} from './constant';

import { useMutation } from 'react-query';

import { Component } from './component';
import {
  boolean,
  maxLength,
  minLength,
  nameReg,
  notEnglishLetter,
  notRussiaLatter,
  onlyTextAndNumber,
  password,
  phoneOperator,
  phoneUA,
  required,
} from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { sendAuthSignupData } from './action';

import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { i18n } from '../../lib/lang';

const config = {
  [FORM_VALUE_ENUM.FIRST_NAME]: [
    required,
    notRussiaLatter,
    nameReg,
    notEnglishLetter,
  ],
  [FORM_VALUE_ENUM.PHONE]: [required, phoneUA, phoneOperator],
  [FORM_VALUE_ENUM.SHOP]: [required, notRussiaLatter],
  [FORM_VALUE_ENUM.CODE]: [required, minLength(2)],
  [FORM_VALUE_ENUM.REGISTRATION_SOURCE]: [required, notRussiaLatter],
  [FORM_VALUE_ENUM.AGE_AGREE]: [boolean],
  [FORM_VALUE_ENUM.RULES_AGREE]: [boolean],
};

const validate = (values: FormikValues) => validation(values, config);

export const Container: React.FC<{ isOpenModal?: boolean }> = (isOpenModal) => {
  const promoId = Number(i18n.t('TEFAL.HOME.PROMO_ID'));
  const unknownError = i18n.t('VALIDATION.UNKNOWN_ERROR');

  const initialValues = {
    [FORM_VALUE_ENUM.FIRST_NAME]: '',
    [FORM_VALUE_ENUM.PHONE]: '',
    [FORM_VALUE_ENUM.CODE]: '',
    [FORM_VALUE_ENUM.SHOP]: null,
    [FORM_VALUE_ENUM.PROMO_ID]: promoId,
    [FORM_VALUE_ENUM.REGISTRATION_SOURCE]: isBrowser
      ? SOURCE_ENUM.DESKTOP
      : SOURCE_ENUM.MOBILE,
    [FORM_VALUE_ENUM.AGE_AGREE]: false,
    [FORM_VALUE_ENUM.RULES_AGREE]: false,
  };

  const action = useMutation(sendAuthSignupData, {});

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    validateOnMount: false,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate({
        [FORM_VALUE_ENUM.CODE]: values[FORM_VALUE_ENUM.CODE],
        [FORM_VALUE_ENUM.PHONE]: values[FORM_VALUE_ENUM.PHONE],
        [FORM_VALUE_ENUM.PROMO_ID]: values[FORM_VALUE_ENUM.PROMO_ID],
        [FORM_VALUE_ENUM.REGISTRATION_SOURCE]:
          values[FORM_VALUE_ENUM.REGISTRATION_SOURCE],
        [FORM_VALUE_ENUM.FIRST_NAME]: values[FORM_VALUE_ENUM.FIRST_NAME],
        [FORM_VALUE_ENUM.SHOP]: values[FORM_VALUE_ENUM.SHOP],
      });
    },
  });

  const resetForm = () => {
    action.reset();
    formik.setFieldValue(FORM_VALUE_ENUM.CODE, '');
  };

  useEffect(() => {
    resetForm();
  }, [isOpenModal]);

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }
    if (action.isLoading) {
      return true;
    }

    // if (action.isSuccess) {
    //   return true;
    // }

    return false;
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: any = action.error as any;
    if (error) {
      if (
        (error?.response?.message || error?.message) &&
        error.statusCode === 400
      ) {
        return i18n.t(
          `TEFAL.ERROR_MESSAGE.${
            error?.response?.message || error?.message.replace('ERROR.', '')
          }`,
          {
            value: error?.error,
          },
        );
      } else {
        return i18n.t(unknownError);
      }
    }
  };

  const responseObserver = () => {
    const error = isError();
    if (error) {
      formik.setFieldError(FORM_VALUE_ENUM.CODE, i18n.t('VALIDATION.CODE'));
    }
  };

  const isSuccess = () => {
    if (action.isSuccess && !action.isLoading) {
      return true;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = async (
    name: FORM_VALUE_TYPE,
    e: SELECT_OPTION_ITEM_DATA,
  ) => {
    await formik.setFieldValue(name, e.value);
    await formik.setFieldValue(`${name}_label`, e.label);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const isDisabled = (name: FORM_VALUE_TYPE) => {
    return formik.values[name] === '' ? true : undefined;
  };

  useEffect(() => responseObserver(), [getErrorMessage(), isError()]);

  // const prizeData = (action.data as any)?.prize;
  // const isCircle = (action.data as any)?.isCircle;

  return (
    <Component
      data={action.data}
      resetForm={resetForm}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      setFieldValue={setFieldValue}
      formik={formik}
      isLoading={isLoading()}
      isError={isError()}
      errorMessage={getErrorMessage()}
      isDisabled={isDisabled}
      setValue={setValue}
      isSuccess={isSuccess()}
    />
  );
};
