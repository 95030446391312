import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { TextElem } from '../text';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';

export const Elem: React.FC<{
  onClick?: () => void;
  tid: string;
  href?: string;
  isTargetBlank?: boolean;
  disabled?: boolean;
}> = ({ tid, onClick, href, isTargetBlank, disabled }) => {
  return (
    <AStyled target={isTargetBlank ? '_blank' : ''} href={href && href}>
      <ButtonContainer disabled={disabled} onClick={onClick}>
        <TitleStyled size="button" type="medium" color="white" tid={tid} />
      </ButtonContainer>
    </AStyled>
  );
};

const AStyled = styled.a`
  width: 100%;
`;

const ButtonContainer = styled.button`
  transition: 0.3s all ease;
  width: 100%;
  border-radius: 20px;
  position: relative;
  z-index: 2;
  padding: 12px 20px;
  box-shadow: 0px 15px 0px 0px #715e96;

  background-color: #8c73b4;

  @media screen and (max-width: 720px) {
    border-radius: 12px;
  }

  :hover {
    background-color: #a389cd;
  }
  :active {
    background-color: #715e96;
  }
  :disabled {
    box-shadow: 0px 15px 0px 0px #b8aacd;
    background-color: #ccc2dc;
  }
`;

const Text = styled(TextElem)`
  font-family: 'Graphik' !important;
`;

const TitleStyled = styled(Text)`
  line-height: 1.2em;
  z-index: 100;
  position: relative;
  text-transform: uppercase;

  @media screen and (max-width: 720px) {
    font-size: 16px;
  }
`;
