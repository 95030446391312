import React from 'react';
import styled from 'styled-components';

import { i18n } from '../../../../../lib/lang';
import { RegistarionButton } from '../../../../../common/registration-button';
import { TgButton } from '../../../../../common/tg-button';
import TefalPrize from '../../../../../asset/tefal/tefal-prize.png';
import ChickPrize from '../../../../../asset/tefal/chick.png';
import Prize from '../../../../../asset/tefal/prize.png';

export const Registration: React.FC<{ openModal: () => void }> = ({
  openModal,
}) => {
  const telegramHref = i18n.t('TEFAL.HOME.TELEGRAM');

  return (
    <Container>
      <ChickImg src={ChickPrize} alt="chicken" />
      <ImgContainer>
        <PrizeMobileImg src={Prize} />
      </ImgContainer>
      <ButtonContainer>
        <RegistarionButton tid="TEFAL.HOME.CODE_BUTTON" onClick={openModal} />
        <TgButton
          isTargetBlank
          tid="TEFAL.HOME.TG_BUTTON"
          href={telegramHref}
        />
      </ButtonContainer>
      <TefalImg src={TefalPrize} alt="tefal prize" />
    </Container>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  max-width: 432px;
  width: 100%;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 720px) {
    max-width: unset;
  }
`;

const TefalMobileImg = styled.img`
  max-width: 270px;
  width: 100%;

  @media screen and (max-width: 665px) {
    width: 203px;
  }
  @media screen and (max-width: 509px) {
    width: 142px;
  }

  @media screen and (max-width: 379px) {
    width: 100px;
  }
`;

const PrizeMobileImg = styled.img`
  width: 495px;
  height: auto;
`;

const ImgContainer = styled.div`
  display: none;
  gap: 44px;

  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }
`;

const ChickImg = styled.img`
  position: absolute;
  left: -60px;
  top: -20px;
  width: 368px;
  height: auto;

  @media screen and (max-width: 1100px) {
    width: 276px;
    top: 0px;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const TefalImg = styled.img`
  position: absolute;
  right: -46px;
  top: -120px;
  width: 320px;
  height: auto;

  @media screen and (max-width: 1100px) {
    width: 260px;
    top: -50px;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px;
  position: relative;

  padding: 56px 0px 0px;
`;
