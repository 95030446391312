import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { Storage } from '@ionic/storage';

import { AppStore, APP_MODULE_NAME } from '../../data/app';

export const LocalStore = new Storage();

const initialState = {};

export const reducers = combineReducers({
  [APP_MODULE_NAME]: AppStore,
});

export const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(thunk)),
);
export { useDispatch } from './core';
export { useSelector } from './core';
export type { Action, Dispatch, GetState } from './core';
