import React from 'react';
import styled from 'styled-components';

import { TextElem } from '../../../common/text';

export const Footer: React.FC<{}> = ({}) => {
  return (
    <Wrapper>
      <Container>
        <TextElemStyled
          size="default"
          color="white"
          tid="TEFAL.FOOTER.FIRST_PARAGRAPH"
        />
      </Container>
    </Wrapper>
  );
};

const TextElemStyled = styled(TextElem)`
  font-family: Graphik !important;
  line-height: normal;
  z-index: 3;

  @media screen and (min-width: 615px) and (max-width: 1074px) {
    font-size: 15;
  }
  @media screen and (max-width: 615px) {
    font-size: 12px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  max-width: 1400px;
  flex-direction: column;
  gap: 20px;

  text-align: start;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  position: relative;
  padding: 24px 20px;
  z-index: 4;

  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  background-color: #8c73b4;
`;
