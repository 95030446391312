import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { IonCheckbox } from '@ionic/react';
import { TextElem } from '../text';
import { SIZE_FONT_TYPE } from '../../theme/size';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../theme/value';

export const Elem: React.FC<{
  name?: string;
  tid?: string;
  tvalue?: object;
  text?: string;
  textSize?: SIZE_FONT_TYPE;
  checked?: boolean;
  onClick?: any;
  isLoading?: boolean;
  onChange?: any;
  isCroco?: boolean;
  href?: string;
  hrefTitle?: string;
}> = ({
  checked = false,
  onClick,
  tid,
  tvalue,
  text = null,
  textSize = 'main',
  isLoading,
  onChange,
  name,
  isCroco,
  href,
  hrefTitle,
}) => {
  const [status, setStatus] = useState<boolean>(checked);
  useEffect(() => {
    setStatus(checked);
  }, [checked]);

  const handleClick = (e: any) => {
    e.stopPropagation();

    if (!isLoading) {
      setStatus(!status);
      if (onClick) {
        onClick();
      }
    }

    e.target.name = name;
    e.target.value = !status;

    if (onChange) {
      onChange(e);
    }
  };
  return (
    <Container onClick={handleClick}>
      <Checkbox
        checked={status}
        disabled={isLoading}
        name={name}
        slot="center"
      />
      {!href && (
        <TextElemStyled
          size={textSize}
          lineHeight={false}
          color={isCroco ? 'white' : 'black'}
          tid={tid}
          tvalue={tvalue}
        >
          {text && text}
        </TextElemStyled>
      )}
      {href && (
        <HrefContainer>
          <TextElemStyled
            size={textSize}
            lineHeight={false}
            color={isCroco ? 'white' : 'black'}
            tid={tid}
            tvalue={tvalue}
          >
            {text && text}
          </TextElemStyled>
          <HrefStyled isCroco={isCroco} target="_blank" href={href}>
            <TextElem
              size={textSize}
              lineHeight={false}
              color={isCroco ? 'white' : 'black'}
              tid={hrefTitle}
              tvalue={tvalue}
            />
          </HrefStyled>
        </HrefContainer>
      )}
    </Container>
  );
};

const HrefContainer = styled.div`
  display: inline-block;
`;

const TextElemStyled = styled(TextElem)`
  cursor: pointer;
  line-height: 1em;
`;

const Checkbox = styled(IonCheckbox)`
  --size: 20px;
  --checkmark-width: 6px;
  --background-checked: ${({ theme }) => theme[COLOR_ENUM.SUCCESS]};
  --border-color-checked: ${({ theme }) => theme[COLOR_ENUM.SUCCESS]};
  --border-width: 1.5px;
  --border-radius: 6px;
  --border-style: solid;
  --border-color: #d7dde6;
  --checkmark-width: 5px;
  background: transparent;
  transition: color ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  ::part(container) {
    padding: 4px;
  }
  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
  min-width: 20px !important;

  margin: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  grid-template-columns: auto 1fr;
  z-index: 1;
`;

const HrefStyled = styled.a<{ isCroco?: boolean }>`
  text-decoration-color: ${({ isCroco }) => (isCroco ? '#ffff' : '#000')};
  line-height: 1em;
`;
