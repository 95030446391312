import React, { useState } from 'react';
import styled from 'styled-components';

import { i18n } from '../../../../lib/lang';
import { TextElem } from '../../../../common/text';
import tefalPng from '../../../../asset/tefal/tefal-button.png';
import phileoPng from '../../../../asset/tefal/phileo-button.png';
import linePng from '../../../../asset/tefal/line.png';
import logoPng from '../../../../asset/tefal/logo-recipe.png';
import logoTefalSvg from '../../../../asset/tefal/tefal-logo.svg';

export const Component: React.FC<{}> = ({}) => {
  const tefalHref = i18n.t('TEFAL.RECIPE.HERO.TEFAL_URL');
  const phileoHref = i18n.t('TEFAL.RECIPE.HERO.PHILEO_URL');

  return (
    <Wrapper>
      <HeaderContainer>
        <AStyled href="recipe#video">
          <TextElem
            size="medium"
            type="medium"
            color="white"
            fontFamily="Raleway"
            tid="TEFAL.RECIPE.HEADER.VIDEO"
          />
        </AStyled>
        <AStyled href="recipe#recipe">
          <TextElem
            size="medium"
            type="medium"
            color="white"
            fontFamily="Raleway"
            tid="TEFAL.RECIPE.HEADER.RECIPE"
          />
        </AStyled>
      </HeaderContainer>

      <Container>
        <LogoContainer>
          <TitleImg src={logoPng} />
          <TitleTefalImg src={logoTefalSvg} />
        </LogoContainer>
        <ContentStyled
          color="white"
          type="bold"
          size="heading"
          fontFamily="Raleway"
          tid="TEFAL.RECIPE.HERO.CONTENT"
        />
      </Container>

      <LearnMoreContainer>
        <LearnMoreTitleContainer>
          <LineImg src={linePng} />

          <LearnMore
            tid="TEFAL.RECIPE.HERO.LEARN_MORE"
            type="bold"
            fontFamily="Vogue Cyr"
            color="white"
            size="heroMiddle"
          />
        </LearnMoreTitleContainer>

        <ButtonContainer>
          <TefalButton target="_blank" href={tefalHref}>
            <TextElem
              fontFamily="Vogue Cyr"
              tid="TEFAL.RECIPE.HERO.TEFAL_BUTTON"
              type="bold"
              color="white"
              size="date_count"
            />
            <TefalImg src={tefalPng} />
          </TefalButton>
          <PhileoButton target="_blank" href={phileoHref}>
            <TextElem
              fontFamily="Vogue Cyr"
              tid="TEFAL.RECIPE.HERO.PHILEO_BUTTON"
              type="bold"
              color="white"
              size="date_count"
            />
            <PhileoImg src={phileoPng} />
          </PhileoButton>
        </ButtonContainer>
      </LearnMoreContainer>
    </Wrapper>
  );
};

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
`;

const LearnMore = styled(TextElem)`
  @media screen and (max-width: 1356px) {
    font-size: 50px;
  }

  @media screen and (max-width: 720px) {
    font-size: 36px;
  }
`;
const ContentStyled = styled(TextElem)`
  max-width: 700px;

  @media screen and (max-width: 1356px) {
    font-size: 20px;
  }

  @media screen and (max-width: 720px) {
    font-size: 16px;
  }
`;

const LineImg = styled.img`
  width: 248px;
  height: auto;

  @media screen and (max-width: 1356px) {
    position: relative;
    right: 200px;
    width: 210px;
  }

  @media screen and (max-width: 720px) {
    width: 157px;
    right: 100px;
  }

  @media screen and (max-width: 373px) {
    right: 0px;
  }
`;

const LearnMoreTitleContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  right: 100px;

  @media screen and (max-width: 1356px) {
    flex-direction: column-reverse;
    right: 0;
  }
`;

const TitleImg = styled.img`
  width: 100%;
  max-width: 555px;
  height: auto;

  @media screen and (max-width: 1356px) {
    max-width: 445px;
  }

  @media screen and (max-width: 720px) {
    max-width: 310px;
  }
`;

const TitleTefalImg = styled.img`
  width: 100%;
  max-width: 376px;
  height: auto;

  @media screen and (max-width: 1356px) {
    max-width: 276px;
  }

  @media screen and (max-width: 720px) {
    max-width: 200px;
  }
`;

const TefalImg = styled.img`
  width: auto;
  height: 84px;
  position: relative;
  top: 3px;

  @media screen and (max-width: 1356px) {
    height: 65px;
    top: 13px;
  }

  @media screen and (max-width: 720px) {
    height: 55px;
    top: 17px;
  }
`;

const PhileoImg = styled.img`
  width: auto;
  height: 84px;
  position: relative;
  bottom: -3px;

  @media screen and (max-width: 1356px) {
    height: 65px;
    bottom: -13px;
  }

  @media screen and (max-width: 720px) {
    height: 55px;
    bottom: -17px;
  }
`;

const ButtonStyled = styled.a`
  transition: 0.3s opacity ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 100px;
  justify-content: space-between;
  max-height: 90px;
  max-width: 600px;
  width: 100%;
  text-decoration: none;

  padding: 29px 68px;

  @media screen and (max-width: 1356px) {
    span {
      font-size: 28px;
    }
  }

  @media screen and (max-width: 1050px) {
    max-width: unset;
  }

  @media screen and (max-width: 720px) {
    padding: 17px 45px;

    span {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 350px) {
    span {
      font-size: 18px;
    }
  }

  :hover {
    opacity: 0.7;
  }
`;

const TefalButton = styled(ButtonStyled)`
  background-color: #e30613;
`;

const PhileoButton = styled(ButtonStyled)`
  background-color: #ffd300;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
`;

const LearnMoreContainer = styled.div`
  padding: 30px 12px 50px;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 25px;
  position: absolute;
  bottom: 0;
  z-index: 100;
`;

const AStyled = styled.a`
  transition: 0.3s opacity ease;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  :hover {
    opacity: 0.7;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 30px;
  width: 100%;
  gap: 20px;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  width: 100%;
  flex-direction: column;
  position: relative;
  padding-bottom: 306px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  max-width: 1500px;
  margin-top: 20px;
  margin-bottom: 300px;
  padding: 0 20px;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
    margin-bottom: 450px;
    gap: 18px;
  }
`;
