import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

import CloseSvg from '../../../asset/img/summer/close.svg';
import ModalImage from '../../../asset/tefal/modal-bg.png';
import WheelImage from '../../../asset/tefal/wheel.png';
import PointerImage from '../../../asset/tefal/pointer.png';
import { TextElem } from '../../../common/text';

const spin = (rotation: number) => keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(${rotation}deg);
  }
`;

export const WheelContent: React.FC<{
  prizeId: number;
}> = ({ prizeId }) => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [currentRotation, setCurrentRotation] = useState(0);

  const calculateRotation = (prizeId: number) => {
    const fullRotations = 360 * 3; // 3 полных оборота
    const sliceRotation = 360 / 6; // Количество секторов (например, 6 секторов)
    const targetRotation = sliceRotation * prizeId;
    return fullRotations + targetRotation; // Общий угол вращения
  };

  useEffect(() => {
    if (prizeId !== undefined && prizeId !== null) {
      const newRotation = calculateRotation(prizeId);
      setCurrentRotation(newRotation);
      setIsSpinning(true);
    }
  }, [prizeId]);

  useEffect(() => {
    if (isSpinning) {
      const timer = setTimeout(() => {
        setIsSpinning(false);
      }, 3000); // Длительность анимации
      return () => clearTimeout(timer);
    }
  }, [isSpinning]);

  return (
    <ContentContainer>
      <WheelContainer>
        <WheelImageStyled isSpinning={isSpinning} rotation={currentRotation} />
        <Pointer src={PointerImage} />
      </WheelContainer>
    </ContentContainer>
  );
};

const Pointer = styled.img`
  width: 10%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  z-index: 10;
`;

const ContentContainer = styled.div`
  height: 70vh;
  max-width: 800px;
  max-height: 600px;
  text-align: center;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
`;

const WheelContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const WheelImageStyled = styled.div<{ isSpinning: boolean; rotation: number }>`
  width: 90%;
  height: 90%;
  background-image: url(${WheelImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  ${(props) =>
    props.isSpinning
      ? css`
          animation: ${spin(props.rotation)} 3s cubic-bezier(0.5, 0, 0.5, 1)
            forwards;
        `
      : css`
          transform: rotate(${props.rotation}deg);
        `}
`;
