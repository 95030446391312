import React from 'react';
import styled from 'styled-components';
import { TextElem } from '../../../../common/text';

export const Component: React.FC = () => {
  return (
    <Wrapper id="video">
      <Iframe
        src={'https://www.youtube.com/embed/Jt76vCkDjlQ/'}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <TextElemStyled
        type="bold"
        color="white"
        size="date_count"
        tid="А тим часом пропонуємо декілька рецептів, у яких Tefal та Суперфілео ідеально “працюють” разом! "
      />
    </Wrapper>
  );
};

const TextElemStyled = styled(TextElem)`
  display: none;
  width: 100%;
  padding: 0 12px;
  text-align: start;

  @media screen and (max-width: 950px) {
    display: block;
  }

  @media screen and (max-width: 430px) {
    font-size: 30px;
  }

  @media screen and (max-width: 400px) {
    font-size: 25px;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  text-align: center;
  width: 100%;
  background-color: #9a6acf;
  gap: 50px;
  @media screen and (max-width: 950px) {
    padding: 50px 0 30px;
    justify-content: unset;
    height: max-content;
  }
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  border: none;

  @media screen and (max-width: 950px) {
    height: 390px;
  }
`;
