import React, { createContext, useContext, ReactNode, FC } from 'react';

interface StoreState {
  data: { [key: string]: any } | null;
}

interface StoreContextProps {
  storeState: StoreState;
  setStoreData: (data: { [key: string]: any }) => void;
  getStoreData: () => { [key: string]: any } | null;
}

const StoreContext = createContext<StoreContextProps | undefined>(undefined);

export const StoreProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [storeState, setStoreState] = React.useState<StoreState>({
    data: null,
  });

  const setStoreData = (data: { [key: string]: any }) => {
    setStoreState((prev) => ({ ...prev, data }));
  };

  const getStoreData = () => storeState.data;

  return (
    <StoreContext.Provider value={{ storeState, setStoreData, getStoreData }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => {
  const context = useContext(StoreContext);

  if (!context) {
    throw new Error('useStore must be used within a StoreProvider');
  }

  return context;
};
