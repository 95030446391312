export const MODULE_NAME = 'SANTA_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const PAGE_PATH = '/';

export const API = {
  MAIN: {
    TYPE: 'GET',
    URL: '/',
  },
};
