import { Dispatch, LocalStore } from '../../lib/store';
import { ACTION_TYPE, MODULE_NAME } from './constant';
import { jwtTokenDecode, setAuthorization } from '../../lib/jwt';
import { Storage } from '@ionic/storage';

let LocalStoreCreated: Storage | null = null;

const getStore = async () => {
  if (LocalStoreCreated) return LocalStoreCreated;

  LocalStoreCreated = await LocalStore.create();

  return LocalStoreCreated;
};

export const appPreloadAllLocalStateData = () => async (dispatch: Dispatch) => {
  dispatch({
    type: ACTION_TYPE.PENDING,
  });

  await getStore();

  dispatch({
    type: ACTION_TYPE.SUCCESS,
  });
};

export const appSaveLocalStateData = async (name: string, data: any) => {
  const localStore = await getStore();

  await localStore.set(name, JSON.stringify(data));
};

export const appClearLocalStateData = async (name: string) => {
  const localStore = await getStore();

  await localStore.remove(name);
};

export const appLoadLocalStateData = async (name: string) => {
  const localStore = await getStore();

  const raw = await localStore.get(name);

  return JSON.parse(raw);
};
