import React from 'react';
import styled, { css } from 'styled-components';

import { useDispatch, useSelector } from './lib/store';
import { APP_MODULE_NAME, appPreloadAllLocalStateData } from './data/app';
import { isRequestSuccess } from './lib/store/service';
import { COLOR_ENUM, LIGHT_COLOR_DATA } from './theme/color';
import { IndexSantaPage } from './epic/santa-home/page/index';
// import { IndexCrocoPage } from './epic/croco-home/page/index';
import { StoreProvider } from './lib/context/store';
import { IndexSummerPage } from './epic/summer-home/page/index';
import { IndexTefalPage } from './epic/tefal-home/page/index';

const App: React.FC = () => {
  const dispatch = useDispatch();

  const { state } = useSelector((s: any) => ({
    state: s[APP_MODULE_NAME],
  }));

  React.useEffect(() => {
    dispatch(appPreloadAllLocalStateData());
  }, []);

  const isSuccess = () => {
    return isRequestSuccess(state.request);
  };

  const success = isSuccess();

  return (
    <StoreProvider>
      <React.Fragment>
        {success && (
          <>
            {/* <IndexPage /> */}
            {/* <IndexSummerPage /> */}
            <IndexTefalPage />
            {/* <IndexSantaPage /> */}
            {/* <IndexCrocoPage /> */}
          </>
        )}
        <Loader success={success} />
      </React.Fragment>
    </StoreProvider>
  );
};

const Loader = styled.div<{ success: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: ${LIGHT_COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  transition: 0.3s;
  pointer-events: none;

  ${({ success }) =>
    success &&
    css`
      opacity: 0;
    `}
`;

export default App;
