import recipe1 from '../../../../asset/tefal/recipe-1.png';
import recipe2 from '../../../../asset/tefal/recipe-2.png';
import recipe3 from '../../../../asset/tefal/recipe-3.png';
import recipe4 from '../../../../asset/tefal/recipe-4.png';

export const DATA = [
  {
    title: 'TEFAL.RECIPE.CARD.LIST.0.TITLE',
    description: 'TEFAL.RECIPE.CARD.LIST.0.DESCRIPTION',
    ingredientPastry: [
      'TEFAL.RECIPE.CARD.LIST.0.INGREDIENT_PASTRY.0',
      'TEFAL.RECIPE.CARD.LIST.0.INGREDIENT_PASTRY.1',
      'TEFAL.RECIPE.CARD.LIST.0.INGREDIENT_PASTRY.2',
      'TEFAL.RECIPE.CARD.LIST.0.INGREDIENT_PASTRY.3',
      'TEFAL.RECIPE.CARD.LIST.0.INGREDIENT_PASTRY.4',
    ],
    ingredientFilling: [
      'TEFAL.RECIPE.CARD.LIST.0.INGREDIENT_FILLING.0',
      'TEFAL.RECIPE.CARD.LIST.0.INGREDIENT_FILLING.1',
      'TEFAL.RECIPE.CARD.LIST.0.INGREDIENT_FILLING.2',
      'TEFAL.RECIPE.CARD.LIST.0.INGREDIENT_FILLING.3',
      'TEFAL.RECIPE.CARD.LIST.0.INGREDIENT_FILLING.4',
      'TEFAL.RECIPE.CARD.LIST.0.INGREDIENT_FILLING.5',
    ],
    ingredient: null,
    preparation: [
      'TEFAL.RECIPE.CARD.LIST.0.PREPARATION.0',
      'TEFAL.RECIPE.CARD.LIST.0.PREPARATION.1',
      'TEFAL.RECIPE.CARD.LIST.0.PREPARATION.2',
      'TEFAL.RECIPE.CARD.LIST.0.PREPARATION.3',
      'TEFAL.RECIPE.CARD.LIST.0.PREPARATION.4',
      'TEFAL.RECIPE.CARD.LIST.0.PREPARATION.5',
      'TEFAL.RECIPE.CARD.LIST.0.PREPARATION.6',
      'TEFAL.RECIPE.CARD.LIST.0.PREPARATION.7',
      'TEFAL.RECIPE.CARD.LIST.0.PREPARATION.8',
      'TEFAL.RECIPE.CARD.LIST.0.PREPARATION.9',
    ],
    img: recipe1,
  },
  {
    title: 'TEFAL.RECIPE.CARD.LIST.1.TITLE',
    ingredient: [
      'TEFAL.RECIPE.CARD.LIST.1.INGREDIENT.0',
      'TEFAL.RECIPE.CARD.LIST.1.INGREDIENT.1',
      'TEFAL.RECIPE.CARD.LIST.1.INGREDIENT.2',
      'TEFAL.RECIPE.CARD.LIST.1.INGREDIENT.3',
      'TEFAL.RECIPE.CARD.LIST.1.INGREDIENT.4',
      'TEFAL.RECIPE.CARD.LIST.1.INGREDIENT.5',
    ],
    preparation: [
      'TEFAL.RECIPE.CARD.LIST.1.PREPARATION.0',
      'TEFAL.RECIPE.CARD.LIST.1.PREPARATION.1',
      'TEFAL.RECIPE.CARD.LIST.1.PREPARATION.2',
      'TEFAL.RECIPE.CARD.LIST.1.PREPARATION.3',
      'TEFAL.RECIPE.CARD.LIST.1.PREPARATION.4',
      'TEFAL.RECIPE.CARD.LIST.1.PREPARATION.5',
    ],
    img: recipe2,
  },
  {
    title: 'TEFAL.RECIPE.CARD.LIST.2.TITLE',
    ingredient: [
      'TEFAL.RECIPE.CARD.LIST.2.INGREDIENT.0',
      'TEFAL.RECIPE.CARD.LIST.2.INGREDIENT.1',
      'TEFAL.RECIPE.CARD.LIST.2.INGREDIENT.2',
      'TEFAL.RECIPE.CARD.LIST.2.INGREDIENT.3',
      'TEFAL.RECIPE.CARD.LIST.2.INGREDIENT.4',
      'TEFAL.RECIPE.CARD.LIST.2.INGREDIENT.5',
    ],
    preparation: [
      'TEFAL.RECIPE.CARD.LIST.2.PREPARATION.0',
      'TEFAL.RECIPE.CARD.LIST.2.PREPARATION.1',
      'TEFAL.RECIPE.CARD.LIST.2.PREPARATION.2',
      'TEFAL.RECIPE.CARD.LIST.2.PREPARATION.3',
      'TEFAL.RECIPE.CARD.LIST.2.PREPARATION.4',
      'TEFAL.RECIPE.CARD.LIST.2.PREPARATION.5',
      'TEFAL.RECIPE.CARD.LIST.2.PREPARATION.6',
      'TEFAL.RECIPE.CARD.LIST.2.PREPARATION.7',
    ],
    img: recipe3,
  },
  {
    title: 'TEFAL.RECIPE.CARD.LIST.3.TITLE',
    ingredient: [
      'TEFAL.RECIPE.CARD.LIST.3.INGREDIENT.0',
      'TEFAL.RECIPE.CARD.LIST.3.INGREDIENT.1',
      'TEFAL.RECIPE.CARD.LIST.3.INGREDIENT.2',
      'TEFAL.RECIPE.CARD.LIST.3.INGREDIENT.3',
      'TEFAL.RECIPE.CARD.LIST.3.INGREDIENT.4',
      'TEFAL.RECIPE.CARD.LIST.3.INGREDIENT.5',
    ],
    preparation: [
      'TEFAL.RECIPE.CARD.LIST.3.PREPARATION.0',
      'TEFAL.RECIPE.CARD.LIST.3.PREPARATION.1',
      'TEFAL.RECIPE.CARD.LIST.3.PREPARATION.2',
      'TEFAL.RECIPE.CARD.LIST.3.PREPARATION.3',
      'TEFAL.RECIPE.CARD.LIST.3.PREPARATION.4',
    ],
    img: recipe4,
  },
];
