import React from 'react';
import styled from 'styled-components';
import { i18n } from '../../../../lib/lang';
import { TextElem } from '../../../../common/text';
import { DATA } from './constant';

export const Component: React.FC = () => {
  return (
    <Wrapper id="recipe">
      {DATA.map((e) => (
        <>
          <Container backgroundImg={e.img}>
            {e?.description ? (
              <Description
                color="white"
                tid={e.description}
                size="heroMiddle"
                type="bold"
              />
            ) : (
              <span />
            )}
            <Img src={e.img} />
            <Content>
              <Title
                fontFamily="Vogue Cyr"
                color="white"
                size="large"
                type="bold"
                tid={e.title}
              />
              {e?.ingredientPastry && (
                <>
                  <TextContent
                    fontFamily="Raleway"
                    color="white"
                    type="bold"
                    size="medium"
                    tid="TEFAL.RECIPE.CARD.INGREDIENT_PASTRY"
                  />
                  <List>
                    {e?.ingredientPastry.map((e) => (
                      <LiStyled>
                        <TextContent
                          fontFamily="Raleway"
                          color="white"
                          type="medium"
                          size="medium"
                          tid={e}
                        />
                      </LiStyled>
                    ))}
                  </List>
                </>
              )}
              {e?.ingredientFilling && (
                <>
                  <TextContent
                    color="white"
                    fontFamily="Raleway"
                    type="bold"
                    size="medium"
                    tid="TEFAL.RECIPE.CARD.INGREDIENT_FILLING"
                  />
                  <List>
                    {e?.ingredientFilling.map((e) => (
                      <LiStyled>
                        <TextContent
                          color="white"
                          type="medium"
                          size="medium"
                          tid={e}
                        />
                      </LiStyled>
                    ))}
                  </List>
                </>
              )}
              {e?.ingredient && (
                <>
                  <TextContent
                    color="white"
                    fontFamily="Raleway"
                    type="bold"
                    size="medium"
                    tid="TEFAL.RECIPE.CARD.INGREDIENT"
                  />
                  <List>
                    {e?.ingredient.map((e) => (
                      <LiStyled>
                        <TextContent
                          color="white"
                          fontFamily="Raleway"
                          type="medium"
                          size="medium"
                          tid={e}
                        />
                      </LiStyled>
                    ))}
                  </List>
                </>
              )}
              {e?.preparation && (
                <>
                  <TextContent
                    color="white"
                    fontFamily="Raleway"
                    type="bold"
                    size="medium"
                    tid="TEFAL.RECIPE.CARD.PREPARATION"
                  />
                  <List>
                    {e?.preparation.map((e) => (
                      <LiStyled>
                        <TextContent
                          color="white"
                          fontFamily="Raleway"
                          type="medium"
                          size="medium"
                          tid={e}
                        />
                      </LiStyled>
                    ))}
                  </List>
                </>
              )}
            </Content>
          </Container>
        </>
      ))}
    </Wrapper>
  );
};

const Title = styled(TextElem)`
  @media screen and (max-width: 950px) {
    font-size: 68px;
  }
`;

const TextContent = styled(TextElem)`
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
`;

const Img = styled.img`
  display: none;
  width: 100%;
  height: auto;
  @media screen and (max-width: 950px) {
    display: block;
  }
`;

const LiStyled = styled.li`
  text-align: start;
  color: #ffff;
`;

const List = styled.ul`
  padding-left: 20px;
  width: 100%;
`;

const Content = styled.div`
  padding: 50px 30px;
  max-width: 555px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  border-radius: 20px;
  justify-content: center;
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: start;

  @media screen and (max-width: 950px) {
    background: rgba(0, 0, 0, 0.2);
    max-width: unset;
    position: relative;
    bottom: 40px;
    padding: 35px 20px;
  }
`;

const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
`;

const Description = styled(TextElem)`
  align-self: flex-end;
  max-width: 930px;
  line-height: 1em;

  @media screen and (max-width: 1500px) {
    align-self: center;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

const Container = styled.div<{ backgroundImg: string }>`
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  text-align: center;
  width: 100%;
  background-color: #9a6acf;
  background: ${({ backgroundImg }) => `url(${backgroundImg})`};
  background-repeat: no-repeat;
  background-size: cover;

  padding: 50px 20px 20px;

  @media screen and (max-width: 950px) {
    background: unset;
    background-color: #9a6acf;
    padding: 0 0 0;
    min-height: unset;
  }
`;
