import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { Footer } from '../../container/footer';
import { Header } from '../../container/header/header';
import { HomeContainer } from '../../container/home';
import BackgroundImage from '../../../../asset/tefal/background.jpg';
import BackgroundMobileImage from '../../../../asset/tefal/background-mobile.png';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <Wrapper>
      <Layout>
        <Header />
        <HomeContainer />
        <Footer />
      </Layout>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #382e7e;
  height: max-content;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;

  background-image: url(${BackgroundImage});
  background-position: 0px -25px;
  background-size: cover;

  @media screen and (max-width: 720px) {
    background-image: url(${BackgroundMobileImage});
    background-position: 0px -400px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 426px) {
    background-position: 0px -185px;
  }
`;
