import React from 'react';
import styled from 'styled-components';

import CloseSvg from '../../../../../asset/img/summer/close.svg';
import { CustomFormContainer } from '../../../../custom-form';
import ModalImage from '../../../../../asset/tefal/modal-bg.png';

export const Modal: React.FC<{ isOpen: boolean; closeModal: () => void }> = ({
  closeModal,
  isOpen,
}) => {
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      // closeModal();
    }
  };

  return (
    <ModalOverlay isOpen={isOpen} onClick={handleOverlayClick}>
      <ModalContent isOpen={isOpen}>
        <CloseImg onClick={closeModal} alt="close" src={CloseSvg} />
        <CustomFormContainer isOpenModal={isOpen} />
      </ModalContent>
    </ModalOverlay>
  );
};

const CloseImg = styled.img`
  position: absolute;
  cursor: pointer;
  padding: 4px;
  top: 16px;
  right: 16px;
  z-index: 1;

  @media screen and (max-width: 720px) {
    top: 20px;
    right: 20px;
  }

  transition: opacity 0.3ms ease-in;

  :hover {
    opacity: 0.7;
  }
`;

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const ModalContent = styled.div<{ isOpen: boolean }>`
  background: #fff;
  border-radius: 20px;
  padding: 40px 64px;
  position: relative;
  background-image: url(${ModalImage});
  background-size: cover;
  background-position: center;
  max-width: 600px;
  width: 100%;
  overflow: auto;

  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transform: translateY(${(props) => (props.isOpen ? 0 : '-20px')});
  transition: opacity 0.3s ease, transform 0.3s ease;

  @media screen and (max-width: 720px) {
    padding: 40px 20px;
  }

  @media screen and (max-height: 530px) {
    height: 100%;
    max-height: 580px;
  }
`;
