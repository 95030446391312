import React from 'react';
import styled from 'styled-components';

import { TextElem } from '../../../../common/text';
import { i18n } from '../../../../lib/lang';
import logoPng from '../../../../asset/tefal/logo.png';
import tefalPng from '../../../../asset/tefal/tefal-logo.svg';
import { Spacing } from '../../../../theme';

export const Header: React.FC<{}> = ({}) => {
  const ruleHref = i18n.t('TEFAL.RULE.BUTTON_HREF');
  const mainHref = i18n.t('TEFAL.MENU.ICON_HREF');
  const mainTefalHref = i18n.t('TEFAL.MENU.ICON_TEFAL_HREF');

  return (
    <Wrapper>
      <Container>
        <ShopContainer>
          <LinkStyled target="_blank" href={mainHref}>
            <TitleImg src={logoPng} />
          </LinkStyled>
          <LinkStyled target="_blank" href={mainTefalHref}>
            <TefalImg src={tefalPng} />
          </LinkStyled>
        </ShopContainer>
        <MobileContainer>
          <ShopContainer>
            <LinkStyled target="_blank" href={mainHref}>
              <TitleImgMobile src={logoPng} />
            </LinkStyled>
            <LinkStyled target="_blank" href={mainTefalHref}>
              <TefalMobileImg src={tefalPng} />
            </LinkStyled>
          </ShopContainer>
          <LinkStyled target="_blank" href={ruleHref}>
            <TextRuleMobile tid="TEFAL.HOME.RULE" color="white" type="medium" />
          </LinkStyled>
        </MobileContainer>

        <DateContainer>
          <TextElem
            tid="TEFAL.HOME.DATE_TITLE"
            color="black"
            type="regular"
            size="default"
          />
          <TextElem
            tid="TEFAL.HOME.DATE"
            color="scarlet"
            type="medium"
            size="main"
          />
        </DateContainer>
        <LinkStyled target="_blank" href={ruleHref}>
          <TextRule
            tid="TEFAL.HOME.RULE"
            color="white"
            type="medium"
            size="heading"
          />
        </LinkStyled>
      </Container>
      <AStyled target="_blank" href="/recipe">
        <ButtonContainer onClick={() => {}}>
          <TitleStyled
            size="main"
            type="medium"
            color="white"
            tid="TEFAL.HOME.RECIPE_BUTTON_TID"
          />
        </ButtonContainer>
      </AStyled>
    </Wrapper>
  );
};

const AStyled = styled.a`
  align-self: flex-start;

  @media screen and (max-width: 989px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.button`
  transition: 0.3s all ease;
  width: 100%;
  border-radius: 20px;
  position: relative;
  z-index: 2;
  padding: 10px 20px;
  box-shadow: rgb(113, 94, 150) 0px 6px 0px 0px;

  background-color: #8c73b4;

  :hover {
    background-color: #a389cd;
  }
  :active {
    background-color: #715e96;
  }
  :disabled {
    box-shadow: 0px 12px 0px 0px #b8aacd;
    background-color: #ccc2dc;
  }
`;

const Text = styled(TextElem)`
  font-family: 'Graphik' !important;
`;

const TitleStyled = styled(Text)`
  line-height: 1.2em;
  z-index: 100;
  position: relative;
  text-transform: uppercase;

  @media screen and (max-width: 720px) {
    font-size: 16px;
  }
`;

const ShopContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacing(2)};
`;

const MobileContainer = styled.div`
  width: 100%;
  display: none;
  align-items: center;

  @media screen and (max-width: 989px) {
    display: flex;
    justify-content: space-between;
  }
`;

const TextRule = styled(TextElem)`
  @media screen and (max-width: 989px) {
    display: none;
  }
`;

const TextRuleMobile = styled(TextElem)`
  font-size: 16px;
  display: none;

  @media screen and (max-width: 989px) {
    display: block;
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #ffffff;
  padding: 16px 40px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  position: relative;
  right: 70px;

  @media screen and (max-width: 989px) {
    padding: 12px;
    left: 0px;
    width: 100%;
  }
`;

const LinkStyled = styled.a`
  transition: 0.3s opacity ease;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  :hover {
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 20px;
  z-index: 2;
  max-width: 1200px;

  @media screen and (max-width: 720px) {
    padding: 30px 20px 50px;
  }

  @media screen and (max-width: 520px) {
    padding: 25px 20px 50px;
  }
`;

const TitleImg = styled.img`
  width: auto;
  height: 48px;

  @media screen and (max-width: 989px) {
    display: none;
  }
`;

const TefalImg = styled.img`
  width: auto;
  height: 50px;

  @media screen and (max-width: 989px) {
    display: none;
  }
`;

const TefalMobileImg = styled.img`
  width: auto;
  height: 40px;
  display: none;

  @media screen and (max-width: 989px) {
    display: block;
  }
`;

const TitleImgMobile = styled(TitleImg)`
  display: none;
  width: 107px;
  height: 40px;

  @media screen and (max-width: 989px) {
    display: block;
  }
`;

const Container = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  z-index: 2;

  @media screen and (max-width: 989px) {
    flex-direction: column;
    gap: 24px;
  }
`;
