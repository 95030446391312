export enum COLOR_ENUM {
  PURPLE = 'purple',
  SCARLET = 'scarlet',
  BLACK = 'black',

  // СТАРЫЕ:
  DEFAULT = 'default', // ДЕФОЛТНЫЙ
  TEXT_PRIMARY = 'textPrimary', // Активный цвет шрифта. Юзается на заголовках и важном тексте
  TEXT_SECONDARY = 'textSecondary', // Основной цвет шрифта. Юзается на дефолтных компонентах, дефолтном тексте данных
  INPUT = 'input', // Цвет инпута и любых других элементов с которыми можно инпутировать
  ERROR = 'error', // Ошибка
  BUTTON_PRIMARY = 'buttonPrimary', // Активный цвет кнопки
  BUTTON_SECONDARY = 'buttonSecondary',
  BORDER = 'border', // Цвет бордера и дивидера
  BORDER_SECONDARY = 'borderSecondary', // Цвет бордера и дивидера
  SUCCESS = 'success', // Цвет успеха
  DISABLED = 'disabled', // Цвет неактивного инпута или чего-либо другого (если у него нет своего цвета неактивности)
  TEXT_THIRD = 'textThird', // Второстепенный не важный цвет текста
  BACKGROUND_PRIMARY = 'backgroundPrimary', // Основноый цвет фона
  BACKGROUND_SECONDARY = 'backgroundSecondary', // Основноый цвет фона
  TEXT_LINK = 'textLink', // Цвет активных ссылок
  LIGHT = 'light', // Светлый цвет
  BUTTON_HOVER = 'buttonHover', // Цвет ховер кнопки
  BUTTON_ACTIVE = 'buttonActive', // цвет фокус/нажатия кнопки
  ACTION = 'action', // CTA цвет
  WHITE = 'white', // Белый цвет
  FOOTER = 'footer', // Белый цвет
  GRADIENT_1 = 'gradient_1',
  GRADIENT_2 = 'gradient_2',
  GRADIENT_3 = 'gradient_3',
  GRADIENT_SOLID_1 = 'gradient_solid_1',
  GRADIENT_SOLID_2 = 'gradient_solid_2',
  GRADIENT_SOLID_3 = 'gradient_solid_3',
  CHECKBOX = 'checkbox',
  CHIP = 'chip',
  TEXT_FOURTH = 'textFourth',
  BUTTON_FOCUS = 'buttonFocus',
  ACTIVE = 'active',
  LINK = 'link',
  DIVIDER = 'divider',
  MODAL_BACKGROUND = 'modalBackground',
  BACK_BUTTON = 'backButton',
  BUTTON_THIRD = 'buttonThird',
  BUTTON_TEXT = 'buttonText',
  TOGGLE_BACKGROUND = 'toggleBackground',
  TOGGLE_HANDLE = 'toggleHandle',
  TOGGLE_HANDLE_ACTIVE = 'toggleHandleActive',
  INPUT_ACTIVE_SHADOW = 'activeInputShadow',
  ACTIVE_SELECT_SHADOW = 'activeSelectShadow',
  SELECT_MENU_DIVIDER = 'selectMenuDivider',
  SELECT_MENU_BACKGROUND = 'selectMenuBackground',
  MENU_BACKGROUND = 'menuBackground',
  INPUT_ACTIVE = 'inputActive',
  NEWS_TITLE = 'newsTitle',
  ERROR_BACKGROUND = 'error_background',
  SUCCESS_BACKGROUND = 'success_background',
  SECONDARY_INPUT = 'secondaryInput',
  HEADER_BACKGROUND = 'headerBackground',
  CALENDAR_DATE = 'calendarDate',
  CHIP_SECONDARY = 'chipSecondary',
  MODAL_CANCEL = 'modelCancel',
  MENU_APP_BACKGROUND = 'menuAppBackground',
  BORDER_THIRD = 'borderThird',
  PALE_BLUE = 'paleBlue',
  FINANCE_BLUE = 'financeBlue',
  FINANCE_GREEN = 'financeGreen',
  FINANCE_RED = 'financeRed',
  FINANCE_ITEM = 'financeItem',
  CHECKBOX_NONE = 'checkboxNone',
  CHECKBOX_IN_PROGRESS = 'checkboxInprogress',
  CHECKBOX_DONE = 'checkboxDone',
  CHECKBOX_NEW = 'checkboxNew',
  RECEIPTS_AMOUNT = 'receiptsAmount',
  COSTS = 'costs',
  DEBT = 'debt',
  DAY_BUTTON = 'dayButton',
  CHECKBOX_INACTIVE = 'checkboxInactive',
}

export type COLOR_TYPE = `${COLOR_ENUM}`;

export const COLOR_DATA = {
  [COLOR_ENUM.PURPLE]: '#8C73B4',
  [COLOR_ENUM.SCARLET]: '#E30613',
  [COLOR_ENUM.BLACK]: '#1A1C1E',
  // СТАРЫЕ:
  [COLOR_ENUM.DEFAULT]: '#2C2C2C',
  [COLOR_ENUM.TEXT_PRIMARY]: '#2C2C2C',
  [COLOR_ENUM.TEXT_SECONDARY]: '#6F7378',
  [COLOR_ENUM.INPUT]: '#FFFFFF',
  [COLOR_ENUM.ERROR]: '#E30613',
  [COLOR_ENUM.BUTTON_PRIMARY]: '#26ABF6',
  [COLOR_ENUM.BUTTON_SECONDARY]: '#186C9B',
  [COLOR_ENUM.BORDER]: '#186C9B',
  [COLOR_ENUM.SUCCESS]: '#219E02',
  [COLOR_ENUM.DISABLED]: '#888888',
  [COLOR_ENUM.TEXT_THIRD]: '#808080',
  [COLOR_ENUM.TEXT_FOURTH]: '#888888',
  [COLOR_ENUM.BACKGROUND_PRIMARY]: '#FEF1E8',
  [COLOR_ENUM.BACKGROUND_SECONDARY]: '#E4F0FD',
  [COLOR_ENUM.GRADIENT_1]:
    'linear-gradient(165.51deg, #FCF5EC 29.89%, #BDDFF6 116.05%)',
  [COLOR_ENUM.GRADIENT_2]:
    'linear-gradient(164.36deg, #FBF3F4 -9.57%, #B8D0E8 125.1%), #FFFFFF;',
  [COLOR_ENUM.GRADIENT_3]:
    'linear-gradient(350.68deg, #C4E2F4 -46.66%, #FFFFFF 107.3%)',
  [COLOR_ENUM.GRADIENT_SOLID_1]: '#FCF5EC',
  [COLOR_ENUM.GRADIENT_SOLID_2]: '#F2EEF2',
  [COLOR_ENUM.GRADIENT_SOLID_3]: '#FAFCFE',
  [COLOR_ENUM.TEXT_LINK]: '#1D84BE',
  [COLOR_ENUM.LIGHT]: '#E6E6E6',
  [COLOR_ENUM.BUTTON_HOVER]: '#2198DC',
  [COLOR_ENUM.BUTTON_ACTIVE]: '#1F86C1',
  [COLOR_ENUM.ACTION]: '#26ABF6',
  [COLOR_ENUM.WHITE]: '#fff',
  [COLOR_ENUM.FOOTER]: '#F7F7F7',
  [COLOR_ENUM.CHECKBOX]: '#30C20B',
  [COLOR_ENUM.CHIP]: '#387ABB',
  [COLOR_ENUM.BORDER_SECONDARY]: '#D8D8D8',
  [COLOR_ENUM.BUTTON_FOCUS]: '#2198DC',
  [COLOR_ENUM.ACTIVE]: '#00A0FA',
  [COLOR_ENUM.LINK]: '#7E7E83',
  [COLOR_ENUM.DIVIDER]: '#EBEBEB',
  [COLOR_ENUM.MODAL_BACKGROUND]: '#FFFFFF',
  [COLOR_ENUM.BACK_BUTTON]: '#6F7378',
  [COLOR_ENUM.BUTTON_THIRD]: '#6B7CA5',
  [COLOR_ENUM.BUTTON_TEXT]: '#fff',
  [COLOR_ENUM.ERROR_BACKGROUND]: '#ffeeee',
  [COLOR_ENUM.SUCCESS_BACKGROUND]: '#fff',
  [COLOR_ENUM.HEADER_BACKGROUND]: '#F2F7FE',
  [COLOR_ENUM.CALENDAR_DATE]: '#38383A',
  [COLOR_ENUM.CHIP_SECONDARY]: '#96B8DE',
  [COLOR_ENUM.MODAL_CANCEL]: '#F9F9F9',
  [COLOR_ENUM.MENU_APP_BACKGROUND]: '#F9F9F9',
  [COLOR_ENUM.BORDER_THIRD]: '#5ECDF1',
  [COLOR_ENUM.PALE_BLUE]: '#a3deff',
  [COLOR_ENUM.FINANCE_BLUE]: '#1b7bb1',
  [COLOR_ENUM.FINANCE_GREEN]: '#136556',
  [COLOR_ENUM.FINANCE_RED]: '#2C2C2C',
  [COLOR_ENUM.FINANCE_ITEM]: '#A3D0F9',
  [COLOR_ENUM.CHECKBOX_NONE]: '#DADADA',
  [COLOR_ENUM.CHECKBOX_IN_PROGRESS]: '#30C20B',
  [COLOR_ENUM.CHECKBOX_DONE]: '#00A0FA',
  [COLOR_ENUM.CHECKBOX_NEW]: '#31C5D8',
  [COLOR_ENUM.RECEIPTS_AMOUNT]: '#30C20B',
  [COLOR_ENUM.COSTS]: '#EB5757',
  [COLOR_ENUM.DEBT]: '#FD4C4C',
  [COLOR_ENUM.CHECKBOX_INACTIVE]: '#2C2C2C',
};

export const LIGHT_COLOR_DATA = {
  [COLOR_ENUM.PURPLE]: '#8C73B4',
  [COLOR_ENUM.SCARLET]: '#E30613',
  [COLOR_ENUM.BLACK]: '#1A1C1E',

  // СТАРЫЕ:
  [COLOR_ENUM.DEFAULT]: '#2C2C2C',
  [COLOR_ENUM.TEXT_PRIMARY]: '#2C2C2C',
  [COLOR_ENUM.TEXT_SECONDARY]: '#6F7378',
  [COLOR_ENUM.INPUT]: '#FFFFFF',
  [COLOR_ENUM.ERROR]: '#E30613',
  [COLOR_ENUM.BUTTON_PRIMARY]: '#26ABF6',
  [COLOR_ENUM.BUTTON_SECONDARY]: '#186C9B',
  [COLOR_ENUM.BORDER]: '#186C9B',
  [COLOR_ENUM.SUCCESS]: '#219E02',
  [COLOR_ENUM.DISABLED]: '#888888',
  [COLOR_ENUM.TEXT_THIRD]: '#808080',
  [COLOR_ENUM.TEXT_FOURTH]: '#2C2C2C',
  [COLOR_ENUM.BACKGROUND_PRIMARY]: '#FEF1E8',
  [COLOR_ENUM.BACKGROUND_SECONDARY]: '#E4F0FD',
  [COLOR_ENUM.GRADIENT_1]:
    'linear-gradient(165.51deg, #FCF5EC 29.89%, #BDDFF6 116.05%)',
  [COLOR_ENUM.GRADIENT_2]:
    'linear-gradient(164.36deg, #FBF3F4 -9.57%, #B8D0E8 125.1%), #FFFFFF;',
  [COLOR_ENUM.GRADIENT_3]:
    'linear-gradient(350.68deg, #C4E2F4 -46.66%, #FFFFFF 107.3%)',
  [COLOR_ENUM.GRADIENT_SOLID_1]: '#FCF5EC',
  [COLOR_ENUM.GRADIENT_SOLID_2]: '#F2EEF2',
  [COLOR_ENUM.GRADIENT_SOLID_3]: '#FAFCFE',
  [COLOR_ENUM.TEXT_LINK]: '#1D84BE',
  [COLOR_ENUM.LIGHT]: '#E6E6E6',
  [COLOR_ENUM.BUTTON_HOVER]: '#2198DC',
  [COLOR_ENUM.BUTTON_ACTIVE]: '#1F86C1',
  [COLOR_ENUM.ACTION]: '#26ABF6',
  [COLOR_ENUM.WHITE]: '#fff',
  [COLOR_ENUM.FOOTER]: '#F7F7F7',
  [COLOR_ENUM.CHECKBOX]: '#30C20B',
  [COLOR_ENUM.CHIP]: '#387ABB',
  [COLOR_ENUM.BORDER_SECONDARY]: '#D8D8D8',
  [COLOR_ENUM.BUTTON_FOCUS]: '#2198DC',
  [COLOR_ENUM.ACTIVE]: '#00A0FA',
  [COLOR_ENUM.LINK]: '#7E7E83',
  [COLOR_ENUM.DIVIDER]: '#EBEBEB',
  [COLOR_ENUM.MODAL_BACKGROUND]: '#FFFFFF',
  [COLOR_ENUM.BACK_BUTTON]: '#2C2C2C',
  [COLOR_ENUM.BUTTON_THIRD]: '#E4F0FD',
  [COLOR_ENUM.BUTTON_TEXT]: '#fff',
  [COLOR_ENUM.TOGGLE_BACKGROUND]: '#DADADA',
  [COLOR_ENUM.TOGGLE_HANDLE]: '#FFFFFF',
  [COLOR_ENUM.TOGGLE_HANDLE_ACTIVE]: '#FFFFFF',
  [COLOR_ENUM.INPUT_ACTIVE]: '#FFFFFF',
  [COLOR_ENUM.INPUT_ACTIVE_SHADOW]:
    '1.76265px 16.9084px 34px rgba(44, 44, 44, 0.06), 0.892341px 8.55986px 14.8219px rgba(44, 44, 44, 0.0405), 0.35253px 3.38167px 5.525px rgba(44, 44, 44, 0.03), 0.0771159px 0.739741px 1.96563px rgba(44, 44, 44, 0.0195)',
  [COLOR_ENUM.ACTIVE_SELECT_SHADOW]:
    '1.76265px 16.9084px 34px rgba(44, 44, 44, 0.06), 0.892341px 8.55986px 14.8219px rgba(44, 44, 44, 0.0405), 0.35253px 3.38167px 5.525px rgba(44, 44, 44, 0.03), 0.0771159px 0.739741px 1.96563px rgba(44, 44, 44, 0.0195)',
  [COLOR_ENUM.SELECT_MENU_DIVIDER]: '#a3d0f9',
  [COLOR_ENUM.SELECT_MENU_BACKGROUND]: '#fff',
  [COLOR_ENUM.MENU_BACKGROUND]: '#fff',
  [COLOR_ENUM.NEWS_TITLE]: '#1D84BE',
  [COLOR_ENUM.ERROR_BACKGROUND]: '#ffeeee',
  [COLOR_ENUM.SUCCESS_BACKGROUND]: '#fff',
  [COLOR_ENUM.SECONDARY_INPUT]: '#c6e1fb',
  [COLOR_ENUM.HEADER_BACKGROUND]: '#F2F7FE',
  [COLOR_ENUM.CALENDAR_DATE]: '#38383A',
  [COLOR_ENUM.CHIP_SECONDARY]: '#96B8DE',
  [COLOR_ENUM.MODAL_CANCEL]: '#F9F9F9',
  [COLOR_ENUM.MENU_APP_BACKGROUND]: '#F9F9F9',
  [COLOR_ENUM.BORDER_THIRD]: '#5ECDF1',
  [COLOR_ENUM.PALE_BLUE]: '#a3deff',
  [COLOR_ENUM.FINANCE_BLUE]: '#1b7bb1',
  [COLOR_ENUM.FINANCE_GREEN]: '#5c9f3f',
  [COLOR_ENUM.FINANCE_RED]: '#1B7B65',
  [COLOR_ENUM.FINANCE_ITEM]: '#A3D0F9',
  [COLOR_ENUM.CHECKBOX_NONE]: '#DADADA',
  [COLOR_ENUM.CHECKBOX_IN_PROGRESS]: '#30C20B',
  [COLOR_ENUM.CHECKBOX_DONE]: '#00A0FA',
  [COLOR_ENUM.CHECKBOX_NEW]: '#31C5D8',
  [COLOR_ENUM.RECEIPTS_AMOUNT]: '#30C20B',
  [COLOR_ENUM.COSTS]: '#EB5757',
  [COLOR_ENUM.DEBT]: '#FD4C4C',
  [COLOR_ENUM.DAY_BUTTON]: '#FFFFFF',
  [COLOR_ENUM.CHECKBOX_INACTIVE]: '#2C2C2C',
};

export const DARK_COLOR_DATA = {
  [COLOR_ENUM.PURPLE]: '#8C73B4',
  [COLOR_ENUM.SCARLET]: '#E30613',
  [COLOR_ENUM.BLACK]: '#1A1C1E',

  // СТАРЫЕ:
  [COLOR_ENUM.DEFAULT]: '#EFEFF0',
  [COLOR_ENUM.TEXT_PRIMARY]: '#F5F5F5',
  [COLOR_ENUM.TEXT_SECONDARY]: '#F5F5F5',
  [COLOR_ENUM.INPUT]: '#2F3646',
  [COLOR_ENUM.ERROR]: '#E30613',
  [COLOR_ENUM.BUTTON_PRIMARY]:
    'linear-gradient(140.91deg, #31C5D8 -14.93%, #0295A9 148.94%), #FA6400',
  [COLOR_ENUM.BUTTON_SECONDARY]: '#31C5D8',
  [COLOR_ENUM.BORDER]: '#186C9B',
  [COLOR_ENUM.SUCCESS]: '#219E02',
  [COLOR_ENUM.DISABLED]: '#3B404B',
  [COLOR_ENUM.TEXT_THIRD]: '#CFCFCF',
  [COLOR_ENUM.TEXT_FOURTH]: '#CFCFCF',
  [COLOR_ENUM.BACKGROUND_PRIMARY]: '#1E2331',
  [COLOR_ENUM.BACKGROUND_SECONDARY]: '#E4F0FD',
  [COLOR_ENUM.GRADIENT_1]: '#1E2331',
  [COLOR_ENUM.GRADIENT_2]: '#1E2331',
  [COLOR_ENUM.GRADIENT_3]: '#1E2331',
  [COLOR_ENUM.GRADIENT_SOLID_1]: '#1E2331',
  [COLOR_ENUM.GRADIENT_SOLID_2]: '#1E2331',
  [COLOR_ENUM.GRADIENT_SOLID_3]: '#1E2331',
  [COLOR_ENUM.TEXT_LINK]: '#FFFFFF',
  [COLOR_ENUM.LIGHT]: '#E6E6E6',
  [COLOR_ENUM.BUTTON_HOVER]: '#1994A6',
  [COLOR_ENUM.BUTTON_ACTIVE]: '#1F86C1',
  [COLOR_ENUM.ACTION]: '#31C5D8',
  [COLOR_ENUM.WHITE]: '#2F3646', // ????
  [COLOR_ENUM.FOOTER]: '#3F4656',
  [COLOR_ENUM.CHECKBOX]: '#31C5D8',
  [COLOR_ENUM.CHIP]: '#387ABB',
  [COLOR_ENUM.BORDER_SECONDARY]: '#D8D8D8',
  [COLOR_ENUM.BUTTON_FOCUS]: '#2198DC',
  [COLOR_ENUM.ACTIVE]: '#31C5D8',
  [COLOR_ENUM.LINK]: '#7E7E83',
  [COLOR_ENUM.DIVIDER]: '#757983',
  [COLOR_ENUM.MODAL_BACKGROUND]: '#3F4656',
  [COLOR_ENUM.BACK_BUTTON]: '#2F3646',
  [COLOR_ENUM.BUTTON_THIRD]: '#6B7CA5',
  [COLOR_ENUM.BUTTON_TEXT]: '#fff',
  [COLOR_ENUM.TOGGLE_BACKGROUND]: '#5D6A6B',
  [COLOR_ENUM.TOGGLE_HANDLE]: '#D5D5D5',
  [COLOR_ENUM.TOGGLE_HANDLE_ACTIVE]: '#FFFFFF',
  [COLOR_ENUM.INPUT_ACTIVE]: '#40495F',
  [COLOR_ENUM.INPUT_ACTIVE_SHADOW]:
    '1.76265px 8px 13px rgba(8, 128, 143, 0.13), 0.892341px 8.55986px 14.8219px rgba(13, 112, 124, 0.0405), 0.35253px 3.38167px 5.525px rgba(49, 197, 216, 0.03), 0.0771159px 0.739741px 1.96563px rgba(49, 197, 216, 0.0195)',
  [COLOR_ENUM.ACTIVE_SELECT_SHADOW]: '1px 1px 8px rgba(0, 0, 0, 0.04)',
  [COLOR_ENUM.SELECT_MENU_DIVIDER]: 'rgba(235, 235, 235, 0.2)',
  [COLOR_ENUM.SELECT_MENU_BACKGROUND]: '#485268',
  [COLOR_ENUM.MENU_BACKGROUND]: '#3F4656',
  [COLOR_ENUM.NEWS_TITLE]: '#fff',
  [COLOR_ENUM.ERROR_BACKGROUND]: '#ffeeee',
  [COLOR_ENUM.SUCCESS_BACKGROUND]: '#fff',
  [COLOR_ENUM.SECONDARY_INPUT]: '#485472',
  [COLOR_ENUM.HEADER_BACKGROUND]: '#596276',
  [COLOR_ENUM.CALENDAR_DATE]: '#FFFFFF',
  [COLOR_ENUM.CHIP_SECONDARY]: '#96B8DE',
  [COLOR_ENUM.MODAL_CANCEL]: '#2E3647',
  [COLOR_ENUM.MENU_APP_BACKGROUND]: '#2D323E',
  [COLOR_ENUM.BORDER_THIRD]: '#5ECDF1',
  [COLOR_ENUM.PALE_BLUE]: '#a3deff',
  [COLOR_ENUM.FINANCE_BLUE]: '#1b7bb1',
  [COLOR_ENUM.FINANCE_GREEN]: '#5c9f3f',
  [COLOR_ENUM.FINANCE_RED]: '#1B7B65',
  [COLOR_ENUM.FINANCE_ITEM]: '#A3D0F9',
  [COLOR_ENUM.CHECKBOX_NONE]: '#DADADA',
  [COLOR_ENUM.CHECKBOX_IN_PROGRESS]: '#30C20B',
  [COLOR_ENUM.CHECKBOX_DONE]: '#00A0FA',
  [COLOR_ENUM.CHECKBOX_NEW]: '#31C5D8',
  [COLOR_ENUM.RECEIPTS_AMOUNT]: '#30C20B',
  [COLOR_ENUM.COSTS]: '#EB5757',
  [COLOR_ENUM.DEBT]: '#FD4C4C',
  [COLOR_ENUM.DAY_BUTTON]: '#2F3646',
  [COLOR_ENUM.CHECKBOX_INACTIVE]: '#EFEFF0',
};
