import React, { useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import styled, { css } from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { COLOR_ENUM, LIGHT_COLOR_DATA } from '../../theme/color';
import { Spacing } from '../../theme';
import { PROPS_TYPE } from './constant';
import { TextElem } from '../text';
import { i18n } from '../../lib/lang';
import { VALUE_OPACITY_DATA, VALUE_OPACITY_ENUM } from '../../theme/value';

export const Elem: React.FC<PROPS_TYPE> = ({
  isCroc,
  placeholder,
  onChange,
  defaultValue,
  title,
  options,
  name,
  value,
  error,
  errorMessage,
  noOptionsMessage,
  dynamic,
  onInputChange,

  textOnly = false,
  noAlertContainer = false,
  customComponents,
  className,
  isDisabled = undefined,
}) => {
  const handleChange = (e: any) => {
    onChange(name, e);
  };
  const noOptionsMessageFn = (inputValue: string) => {
    return !inputValue ? 'Почніть вводити' : 'Нічого не знайдено';
  };
  return (
    <Container className={className}>
      {title && (
        <Label>
          <TextElem
            color={isCroc ? 'white' : 'black'}
            size="default"
            type="medium"
            tid={title}
          />
        </Label>
      )}
      {
        <CustomSelect
          value={value}
          classNamePrefix={'Select'}
          options={options}
          placeholder={placeholder && i18n.t(placeholder)}
          onChange={handleChange}
          name={name}
          isSearchable={dynamic || false}
          noOptionsMessage={({ inputValue }) =>
            noOptionsMessage ? noOptionsMessage : noOptionsMessageFn(inputValue)
          }
          onInputChange={onInputChange}
          // menuIsOpen={true}
          menuPlacement="auto"
          textOnly={textOnly}
          defaultValue={defaultValue || 'select'}
          components={customComponents}
          closeMenuOnSelect={true}
          isDisabled={isDisabled}
        />
      }

      {!noAlertContainer && (
        <ErrorContainer>
          {(error || errorMessage) && (
            <ErrorMessage>
              <TextElem size="alert" color="error">
                {errorMessage}
              </TextElem>
            </ErrorMessage>
          )}
        </ErrorContainer>
      )}
    </Container>
  );
};

const ErrorContainer = styled.div`
  height: 13.19px;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 5px;
`;

const ErrorMessage = styled.div``;

const CustomSelect = styled(Select)<{
  textOnly: boolean;
  isDisabled: true | undefined;
}>`
  & .Select__control {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    color: #1a1c1e;
    padding: 9px 16px;
    border-radius: 4px;
    border: 1px solid #d7dde6;
    background: #ffff;

    &:hover {
      border: 1px solid #8c73b4;
    }

    width: 100%;
    path {
      fill: ${LIGHT_COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
    }
  }
  & .Select__control--is-focused {
    box-shadow: ${LIGHT_COLOR_DATA[COLOR_ENUM.INPUT_ACTIVE_SHADOW]};
    background: ${LIGHT_COLOR_DATA[COLOR_ENUM.INPUT_ACTIVE]};
    border: 1px solid #8c73b4;
  }
  & .Select__control--menu-is-open {
    path {
      fill: ${LIGHT_COLOR_DATA[COLOR_ENUM.ACTION]};
    }
    & .Select__indicator.Select__dropdown-indicator {
      transform: rotate(180deg);
    }
  }
  & .Select__value-container {
    padding: 0;
  }

  & .Select__input-container {
    margin: 0;
    padding: 0;
    line-height: 22px;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};
    color: ${LIGHT_COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]} !important;
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
  }
  & .Select__single-value {
    color: ${LIGHT_COLOR_DATA[COLOR_ENUM.BLACK]};
    font-weight: 400;
    font-size: 14px;
  }
  & .Select__placeholder {
    margin: 0;
    color: #8c8f94;
  }
  & .Select__indicator-separator {
    display: none;
  }
  & .Select__indicator.Select__dropdown-indicator {
    width: 17px;
    color: #000;
    padding: 0;
    transition: all 0.3s;
    margin-left: ${Spacing(2)};
  }
  & .Select__menu {
    z-index: 9;
    box-shadow: none;
    margin: 0;
    box-shadow: ${LIGHT_COLOR_DATA[COLOR_ENUM.ACTIVE_SELECT_SHADOW]};
    padding-top: ${Spacing(4)};
    top: 90%;
    border-radius: 4px;
    background-color: ${LIGHT_COLOR_DATA[COLOR_ENUM.WHITE]};
  }
  & .Select__menu-list {
    max-height: 193px;
    padding: 0;
    z-index: 999;
    font-size: 14px !important;
    border-radius: 0px 0px 4px 4px;
    background-color: ${LIGHT_COLOR_DATA[COLOR_ENUM.WHITE]};

    & :last-child {
      border: none;
    }
  }
  & .Select__menu-notice {
    text-align: left;
  }
  & .Select__option {
    color: ${LIGHT_COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]} !important;
    border-bottom: 1px solid rgb(215, 221, 230);
    min-height: 38px;
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.DEFAULT]}px;
    padding: ${Spacing(2.5)} ${Spacing(4)};
  }
  & .Select__option--is-selected {
    background-color: #a389cd;
  }
  & .Select__option--is-focused {
    background-color: #a389cd;
  }

  ${({ textOnly }) => {
    if (textOnly) {
      return css`
        & .Select__control {
          background-color: transparent;
          box-shadow: none !important;
          padding-top: 0;
          padding-bottom: 0;
          height: 50px;
        }
        & .Select__menu {
          border-radius: 4px;
          padding-top: 0;
          right: 0;
          width: 100%;
          min-width: fit-content;
          background: ${({ theme }) =>
            theme[COLOR_ENUM.SELECT_MENU_BACKGROUND]};
        }
        & .Select__menu-list {
          border-radius: 4px;
          background: ${({ theme }) =>
            theme[COLOR_ENUM.SELECT_MENU_BACKGROUND]};
        }
      `;
    }
  }}
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        & .Select__control {
          opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.DEFAULT]};
        }
      `;
    }
  }}
`;
const Label = styled.div``;
