import { API, FORM_VALUE_INTER } from './constant';
import { HttpRequest } from '../../lib/http';

export const sendAuthSignupData = (payload: FORM_VALUE_INTER): any => {
  return HttpRequest({
    method: API.MAIN.TYPE,
    url: API.MAIN.URL,
    data: payload,
  });
};
